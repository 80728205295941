import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";

const UserMenuSidebar = () => {
  const [t] = useTranslation();

  return (
    <aside className="main-sidebar bg-bg_color bg_color elevation-5">
      <div className="sidebar">
        <div className="user-sidebar mt-3 pb-3 mb-3 d-flex">
          <NavLink to="/" className="text-center">
            <div
              style={{
                backgroundColor: "transparent",
                height: "35px",
                paddingTop: "6px",
              }}
            >
              <img src="/img/Logo_negative.svg" alt="logo" width="80%" />
            </div>
          </NavLink>
        </div>
        <nav className="mt-6" style={{ width: "60%" }}>
          <ul
            className="nav nav-pill nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink to="/dashboard" exact className="nav-link">
                <MdDashboard size={22} />
                <p className="ml-2">
                  {t("userMenuSidebar.label.AC-dashboard")}
                </p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/account-details" exact className="nav-link">
                <FaUser size={22} />
                <p className="ml-2">
                  {t("userMenuSidebar.label.AccountDetails")}
                </p>
              </NavLink>
            </li>
            {localStorage.getItem("type") == "Private" ? null :
              <>
                <li className="nav-item">
                  <NavLink to="/change-org" exact className="nav-link">
                    <BiUserCircle size={22} />
                    <p className="ml-2">{t("userMenuSidebar.label.ChangeOrg")}</p>
                  </NavLink>
                </li>
              </>}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default UserMenuSidebar;
