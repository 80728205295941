import React from "react";
import { useTranslation } from "react-i18next";
import { BiVideo } from "react-icons/bi";

const InstructionVideo = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="container justify-content-center mt-2">
        <div className="text-center">
          <h3>
            <BiVideo size={25} />
            {t("userMenuSidebar.label.InstructionVideos")}
          </h3>
        </div>

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link btn-dark active"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="true"
            >
              {t("IntroductionVideo.label.Danish")}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button
              class="nav-link btn-dark  mx-3"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="false"
            >
              {t("IntroductionVideo.label.English")}
            </button>
          </li>
        </ul>
        <hr />

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="container mt-3">
              <div className="row">
                <div className="col-12">
                  <h2>Paramediciner : Martin Assentoft</h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/815813767?h=b395673b1b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          width: "100%",
                          height: "500px",
                        }}
                        title="DA-01-wo-sub-Paramediciner-Martin Assentoft"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Formål med video</b>: fortælle om problemer, der kan
                    opstå for lægpersoner, når en ulykke er sket og hvordan
                    Action-cards kan hjælpe med at afhjælpe disse problemer.
                    Vises i starten af et kursus og i markedsføring.
                  </h6>
                  <hr />
                </div>

                <div className="col-12 mt-2">
                  <h2>Action-cards® instruktør - Asger Helmig</h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/807825478?h=b0d6749ae6&badge=0&autopause=0&player_id=0&app_id=58479"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          width: "100%",
                          height: "500px",
                        }}
                        title="DA-02-Instruktionsvideo-Asger Helmig"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Formål med video</b>: A til Z video, der fortæller
                    hvordan førstehjælpstræning med Action-cards forløber. Vises
                    i starten af et kursus.
                  </h6>
                  <hr />
                </div>

                <div className="col-12 mt-2 mb-3">
                  <h2>Uddannelsesansvarlig i forsvaret - Søren Marcussen</h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/811959237?h=29512bca55&badge=0&autopause=0&player_id=0&app_id=58479"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          width: "100%",
                          height: "500px",
                        }}
                        title="DA-03-Uddannelsesansvarlig i Forsvaret-S&oslash;ren Marcussen"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Formål med video</b>: Give indblik i 5
                    krisestyringsproblemer for mindre grupper og hvordan man kan
                    håndtere disse problemer. Vises undervejs i et kursus.
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade "
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="container mt-3">
              <div className="row">
                <div className="col-12">
                  <h2>Paramedic: Martin Assentoft</h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/818458381?h=2062d4eb5b&badge=0&autopause=0&player_id=0&app_id=58479%22"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        className="instruction-video"
                        title="ENG-01-wo-sub-Paramedic-Martin Assentoft"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Purpose of video</b>: tell about problems that can arise
                    for lay people when an accident has occurred and how
                    Action-cards can help to remedy these problems. Appears at
                    the start of a course and in marketing
                  </h6>
                  <hr />
                </div>

                <div className="col-12 mt-2">
                  <h2>Action-cards® instructor - Asger Helmig</h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/820853123?h=a671830a53&badge=0&autopause=0&player_id=0&app_id=58479"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        className="instruction-video"
                        allowfullscreen
                        title="ENG-02-Instruction video-Asger Helmig"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Purpose of video</b>: A to Z video that explains how
                    first aid drills with Action cards proceeds. Appears at the
                    start of a course.
                  </h6>
                  <hr />
                </div>

                <div className="col-12 mt-2 mb-3">
                  <h2>
                    Armed Forces Educational responsible - Søren Marcussen
                  </h2>
                  <div class="card">
                    <div class="card-body">
                      <iframe
                        src="https://player.vimeo.com/video/820853359?h=67b3c9b036&badge=0&autopause=0&player_id=0&app_id=58479"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        className="instruction-video"
                        title="ENG-03-Education Officer in the Army-S&oslash;ren Marcussen"
                      ></iframe>
                    </div>
                  </div>
                  <h6>
                    <b>Purpose of video</b>: Give insight into 5 crisis
                    management problems for smaller groups and how to deal with
                    problems. Appears during a course.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InstructionVideo;
