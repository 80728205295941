import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";

const ChangeOrganization = () => {
  const [t] = useTranslation();
  const jwtToken = localStorage.getItem("jwt_token");

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = async (selectedOption) => {
    await localStorage.setItem("organisation_id", selectedOption.value);
    await localStorage.setItem("customer_id", selectedOption.customer_id);
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/login-user-connected-in-organzation`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          const mappedOptions = data.data.map((option) => {
            return {
              label: option.name,
              value: option.organisation_id,
              customer_id: option.customer_id,
            };
          });
          setOptions(mappedOptions);
          const selectedOptionMatch = mappedOptions.find(
            (option) =>
              option.customer_id === +localStorage.getItem("customer_id") &&
              option.value === +localStorage.getItem("organisation_id")
          );
          setSelectedOption(selectedOptionMatch);
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  return (
    <>
      <div className="container justify-content-center mt-2">
        <div className="text-center">
          <h3>
            <BiUserCircle size={30} />
            {t("userMenuSidebar.label.ChangeOrg")}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 col-lg-4">
          <Select
            lassName="basic-single"
            classNamePrefix="select"
            value={selectedOption}
            name="color"
            options={options}
            onChange={handleSelectChange}
          />
        </div>
      </div>
    </>
  );
};
export default ChangeOrganization;
