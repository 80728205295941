import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiVideo } from "react-icons/bi";
import { DiGoogleAnalytics } from "react-icons/di";
import { FaUserCog } from "react-icons/fa";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [t] = useTranslation();
  const jwtToken = localStorage.getItem("jwt_token");

  const getOrganizationDetail = async () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/login-user-connected-in-organzation`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          if (!localStorage.getItem('customer_id')) {
            const organizationDetail = data.data[0];
            localStorage.setItem(
              "organisation_id",
              organizationDetail.organisation_id
            );
            localStorage.setItem(
              "customer_id",
              organizationDetail.customer_id
            );
          }
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getOrganizationDetail();
  }, []);

  return (
    <>
      <div className="text-center">
        <h4>Select Your Product</h4>

        <div className="container mt-3">
          <div className="row d-flex align-items-stretch">
            <div className="col-md-4 col-sm-6 mb-4">
              <div className="card bg_color h-100">
                <div className="card-body d-flex align-items-center justify-content-center">
                  <NavLink
                    to="/ac-analytics"
                    exact
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    <DiGoogleAnalytics size={50} />
                    <h6 style={{ marginTop: "8px" }}>{t("userMenuSidebar.label.RiskAnalysis")}</h6>
                  </NavLink>
                </div>
              </div>
            </div>
            {localStorage.getItem("type") == "Private" ? <>
              <div className="col-md-4 col-sm-6">
                <div className="card bg_color" >
                  <div className="card-body d-flex align-items-center justify-content-center" style={{ backgroundColor: "lightgrey" }}>
                    <BiVideo size={50} />
                    <h6 style={{ fontSize: "18px", marginTop: "8px" }}>{t("userMenuSidebar.label.InstructionVideos")}</h6>
                    <h6>Coming soon</h6>
                  </div>
                </div>
              </div></>
              : <>
                <div className="col-md-4 col-sm-6 mb-4">
                  <div className="card bg_color h-100">
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <NavLink
                        to="/instruction-video"
                        exact
                        className="nav-link"
                        style={{ color: "white" }}
                      >
                        <BiVideo size={50} />
                        <h6 style={{ marginTop: "8px" }}>{t("userMenuSidebar.label.InstructionVideos")}</h6>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            }

            <div className="col-md-4 col-sm-6 mb-4">
              <div className="card bg_color h-100">
                <div className="card-body d-flex align-items-center justify-content-center">
                  <NavLink
                    to="/administration"
                    exact
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    <FaUserCog size={50} />
                    <h6 style={{ marginTop: "8px" }}>{t("userMenuSidebar.label.Administration")}</h6>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default Dashboard;
