import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = ({ children, ...rest }) => {

  const isLoggedIn = useSelector((state) => {
    return state ? state.auth.isLoggedIn : false;
  });
  const isTokenSet = localStorage.getItem('accessToken') ? true : false;
  const isAuthenticated = isLoggedIn || isTokenSet;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default PublicRoute;
