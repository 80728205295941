import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AddPerson = () => {
  const history = useHistory();
  const jwtToken = localStorage.getItem("jwt_token");
  const { search } = useLocation();
  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [person, setPerson] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: ""
  });

  const add = () => {
    let url = `${process.env.REACT_APP_API_URL}/people`;
    const body = {
      first_name: person.first_name,
      last_name: person.last_name,
      email: person.email,
      phone_number: person.phone_number,
      password: person.password
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data;
        if (codeMessage) {
          history.push("/admin-person")
          toast.success("Data Added");
        } else {
          toast.error("already Exists");
        }
      });
  };
  const onChangeHandler = (e) => {
    let tempObj = { ...person };
    tempObj[e.target.id] = e.target.value;
    setPerson(tempObj);
  };
  const update = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/people/${id}`;
    await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.people_details;
        setPerson({
          first_name: codeMessage.first_name,
          last_name: codeMessage.last_name,
          email: codeMessage.email,
          phone_number: codeMessage.phone_number,
        });
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      // update api call here
      const id = paramIdSeprator(search);
      let url = `${process.env.REACT_APP_API_URL}/people/${id}`;
      const body = {
        first_name: person.first_name,
        last_name: person.last_name,
        email: person.email,
        phone_number: person.phone_number,
        password: person.password
      };
      fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.error !== null) {
            toast.error("Something went wrong!!!");
          } else {
            toast.success("Data Updated");
            history.push("/admin-person")
            ClearData();
          }
        });
    } else {
      add();
      ClearData();
    }
  };

  const ClearData = (e) => {
    setPerson({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      account_type: "",
    });
  };

  useEffect(async () => {

    if (search) {
      const id = paramIdSeprator(search);
      update(id);
    }
    // paramIdSeprator()
  }, [search]);


  return (
    <>
      <div className="row">
        <div className="col-6"><h6>{paramIdSeprator(search) ? "Update" : "Add"} Person</h6></div>
      </div>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="first_name">
              First name:
            </label>
            <input
              type="text"
              className="form-control"
              id="first_name"
              value={person.first_name}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="last_name">
              Last name:
            </label>
            <input
              type="text"
              className="form-control"
              id="last_name"
              value={person.last_name}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="email">
              Email address:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={person.email}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="phone_number">
              Phone number:
            </label>
            <input
              type="phone_number"
              className="form-control"
              id="phone_number"
              value={person.phone_number}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="password">
              Gen. password:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={person.password}
              onChange={onChangeHandler}
            />

            <button type="Update" className="btn m-3 bg_color">
              {paramIdSeprator(search) ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPerson;
