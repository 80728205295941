import React from 'react';
import classes from './PageLoading.module.scss';

const PageLoading = () => {
  return (
    <div className={classes.loading}>
      <span>A</span>
      <span>D</span>
      <span>M</span>
      <span>I</span>
      <span>N</span>
      <span>L</span>
      <span>T</span>
      <span>E</span>
    </div>
  );
};

export default PageLoading;
