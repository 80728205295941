import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const UpdateAccessories = () => {
  const history = useHistory();
  const { search } = useLocation();
  const jwtToken = localStorage.getItem("jwt_token");

  const paramIdSeprator = (param) => {
    const paramId = param.split("&");
    return paramId;
  };
  const location = useLocation();
  const rowData = location.state?.rowData;

  const [accessoriesList, setAccessoriesList] = useState({
    id: "",
    name: "",
    date: "",
    quantity: "",
  });

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accessories-drop-down`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          const mappedOptions = data.data.map((option) => {
            return {
              label: option.ac_accessoriesid,
              value: option.id,
            };
          });
          setOptions(mappedOptions);
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    if (rowData && options.length > 0) {
      const selectedLabel = options.find(
        (option) => option.value === rowData.accessoriesid
      )?.label;
      setSelectedOption(selectedLabel || null);
      setAccessoriesList({
        id: rowData.id,
        name: selectedLabel || "",
        date: rowData.date,
        quantity: rowData.quantity,
      });
    }
  }, [rowData, options]);

  const onChangeHandler = (e) => {
    let tempObj = { ...accessoriesList };
    tempObj[e.target.id] = e.target.value;
    setAccessoriesList(tempObj);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      const data = paramIdSeprator(search);
      console.log(data);
      const accessoriesid = data[0].split("=")[1];
      const id = data[1].split("=")[1];
      let url = `${process.env.REACT_APP_API_URL}/update-accessories/${id}`;
      const body = {
        accessoriesid: accessoriesid,
        name: accessoriesList.name,
        date: accessoriesList.date,
        quantity: accessoriesList.quantity,
        type: rowData.type,
      };
      fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.error !== null) {
            toast.error(data.error);
          } else {
            history.push("/accessories");
            toast.success(data.message);
            ClearData();
          }
        });
    }
  };

  const ClearData = (e) => {
    setAccessoriesList({
      id: "",
      name: "",
      date: "",
      quantity: "",
    });
  };

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="id">
              Item Id:
            </label>
            <input
              type="text"
              className="form-control"
              id="id"
              value={accessoriesList.id}
              onChange={onChangeHandler}
              disabled
            />
            <label className="mt-1" htmlFor="name">
              Item Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={selectedOption || ""}
              onChange={onChangeHandler}
              disabled
            />
            <label className="mt-1" htmlFor="date">
              Date:
            </label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={accessoriesList.date}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="quantity">
              Quantity
            </label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              value={accessoriesList.quantity}
              onChange={onChangeHandler}
            />
            <button type="submit" className="btn mt-3 bg_color">
              Update
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateAccessories;
