import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const UpdateAdministrationDetail = () => {
  const jwtToken = localStorage.getItem("jwt_token");
  const { search } = useLocation();
  const history = useHistory();

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [userDetail, setUserDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    organization_id: "",
  });

  useEffect(() => {
    getAdministrationDetail();
  }, []);

  const onChangeHandler = (e) => {
    let tempObj = { ...userDetail };
    tempObj[e.target.id] = e.target.value;
    setUserDetail(tempObj);
  };

  const getAdministrationDetail = async () => {
    const id = paramIdSeprator(search);
    fetch(`${process.env.REACT_APP_API_URL}/employee/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          const administration = data.data;
          setUserDetail({
            first_name: administration.first_name,
            last_name: administration.last_name,
            email: administration.email,
            phone_number: administration.phone_number,
            organization_id: administration.organization_id,
          });
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      const id = paramIdSeprator(search);
      const body = {
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        email: userDetail.email,
        phone_number: userDetail.phone_number,
        organization_id: userDetail.organization_id,
      };

      fetch(`${process.env.REACT_APP_API_URL}/employee/${id}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            toast.success(data.message);
            history.push("/administration");
          }
          BulkData();
          return Promise.reject(data.error);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">Administration Details</div>
      </div>

      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="first_name">
              First name <span className="danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="first_name"
              required
              value={userDetail.first_name}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="last_name">
              Last name <span className="danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="last_name"
              required
              value={userDetail.last_name}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="email">
              Email address <span className="danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              required
              value={userDetail.email}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="phone_number">
              Phone number <span className="danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="phone_number"
              required
              value={userDetail.phone_number}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="organization_id">
              Organization Id <span className="danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="organization_id"
              required
              value={userDetail.organization_id}
              onChange={onChangeHandler}
            />

            <button type="submit" className="btn m-3 bg_color">
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default UpdateAdministrationDetail;
