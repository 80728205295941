import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaUserCog } from "react-icons/fa";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AnalysticsDataList from "./AnalysticsList";

const Administration = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [bulkData, setBulkData] = useState([]);
  const [loading, setLoading] = useState(false);
  const jwtToken = localStorage.getItem("jwt_token");
  const organizationId = localStorage.getItem("organisation_id");
  const [file, setFile] = useState(null);

  const handleChange = (files) => {
    setFile(files[0]);
  };

  const BulkData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/organization-employee-list?id=${organizationId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          setBulkData(data.data);
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    BulkData();
  }, []);

  const onSubmitHandler = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("organization_id", organizationId);

    fetch(`${process.env.REACT_APP_API_URL}/employee-list/upload-file`, {
      method: "POST",
      body: bodyFormData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
        }
        BulkData();
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const [showEmployeeList, setEmployeeListShow] = useState(false);

  const clicktoShowListhandler = () => {
    setEmployeeListShow(!showEmployeeList);
  };

  return (
    <>
      <div className="container justify-content-center mt-2">
        <div className="text-center">
          <h3>
            <FaUserCog size={28} /> {t("userMenuSidebar.label.Administration")}
          </h3>
        </div>
      </div>

      <div className="row">
        {loading ? (
          <div className="text-center w-full" style={{ width: "100%" }}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {
              localStorage.getItem("type") == "Private" ? null : <><div className="row my-2">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <h5>Upload Your File Here</h5>
                    <FileUploader
                      multiple={true}
                      handleChange={handleChange}
                      label={"Upload or drop a file right here"}
                      types={["CSV"]}
                    />
                    <h6 style={{ color: 'red', fontSize: '12px' }}>*Please note that an email address is required for the CSV file to be added to the database. <br />Without a valid email, the data will not be added.</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 col-md-8 mt-2">
                    <div>
                      <button
                        type="button"
                        className="nav-icon btn btn-lg bg_color"
                        onClick={onSubmitHandler}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="col-5 col-md-4 mt-2">
                    <div>
                      <button className="nav-icon btn btn-lg bg_color mb-1 responsive-btn">
                        <a
                          href="/assets/Documents/ActionCards dummy data.csv"
                          title="click to download"
                          style={{ color: "white" }}
                          download
                        >
                          Download Demo file
                        </a>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-7 col-md-8">
                    <h6 className="mt-3">Employee List</h6>
                  </div>
                  <div className="col-md-4 col-5">
                    <button
                      className="nav-icon btn btn-lg bg_color mt-2 responsive-btn"
                      onClick={clicktoShowListhandler}
                    >
                      {showEmployeeList
                        ? "Hide Employee List"
                        : "Show Employee List"}
                    </button>
                  </div>
                </div>
                {!showEmployeeList && <hr />}
              </div></>
            }


            {showEmployeeList && (
              <>
                <div className="text-center mb-4">
                  <h3>{t("userMenuSidebar.label.EmployeeList")}</h3>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Position</th>
                      <th scope="col">Business Unit</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bulkData.map((item, i) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{item.id}</th>
                          <td>{item.name}</td>
                          <td>{item.first_name}</td>
                          <td>{item.last_name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone_number}</td>
                          <td>{item.employee_id}</td>
                          <td>{item.position}</td>
                          <td>{item.unit_id}</td>
                          <td>
                            <i
                              type="button"
                              className="nav-icon fas fa-edit btn btn-lg bg_color"
                              onClick={() => {
                                history.push({
                                  pathname: `/administration/update`,
                                  search: `?id=${item.id}`,
                                });
                              }}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}

                    <tr hidden={bulkData.length !== 0}>
                      <td colSpan="5" className="text-center">
                        Data Not Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            <AnalysticsDataList />
          </>
        )}
      </div>
    </>
  );
};
export default Administration;
