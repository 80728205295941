import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useLocation } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"

const ActionCardsForm = () => {
  const { id } = useParams()
  const [t] = useTranslation()
  const jwtToken = localStorage.getItem("jwt_token")
  const [selectedLanguage, setSelectedLanguage] = useState("danish")
  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [trainingOptions, setTrainingOptions] = useState([])
  const [actioncardname, setActionCardName] = useState("")
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const [loading, setLoading] = useState(false);

  const languageLabels = {
    danish: {
      "Yes": "Ja",
      "No": "Nej",
      "--Other--": "--Andet--",
      "--Select--": "--Vælg--"
    },
    english: {
      "Yes": "Yes",
      "No": "No",
      "--Other--": "--Other--",
      "--Select--": "--Select--",
    },
  }

  const realIncidentOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const injuredParty = [
    { value: "", label: "--Select--" },
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const potentialWitnesses = [
    { value: "", label: "--Select--" },
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const numberYesOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ]

  const quantityOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ]

  const numberNAOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ]

  const [formValues, setFormValues] = useState({
    selected: realIncidentOptions[0],
    selectedInjuredPartyOption: injuredParty[0],
    selectedPotentialWitnessesOptions: potentialWitnesses[0],
    selectedtrainingOption: trainingOptions ? trainingOptions[0] : "Null",
    selectednumberYesOptions: numberYesOptions[0],
    selectedquantityOptions: quantityOptions[0],
    selectednumberNAOptions: numberNAOptions[0],
    selectedFile: "",
    name: "",
    inputValue: "",
    injuredPartyName: "",
    injuredPartyPhoneNo: "",
    injuredPartyEmail: "",
    potentialWitnessesName: "",
    potentialWitnessesPhoneNo: "",
    potentialWitnessesEmail: "",
  })


  const ClearData = (e) => {
    setFormValues({
      selected: realIncidentOptions[0],
      selectedInjuredPartyOption: injuredParty[0],
      selectedPotentialWitnessesOptions: potentialWitnesses[0],
      selectedtrainingOption: trainingOptions ? trainingOptions[0] : "Null",
      selectednumberYesOptions: numberYesOptions[0],
      selectedquantityOptions: quantityOptions[0],
      selectednumberNAOptions: numberNAOptions[0],
      selectedFile: "",
      name: "",
      inputValue: "",
      injuredPartyName: "",
      injuredPartyPhoneNo: "",
      injuredPartyEmail: "",
      potentialWitnessesName: "",
      potentialWitnessesPhoneNo: "",
      potentialWitnessesEmail: "",
    })
  }

  const [errorMsg, setErrorMsg] = useState(false)

  const handleChange = (selectedOption) => {
    setFormValues({ ...formValues, selected: selectedOption })
    setSelectedOption("")
    setShowDiv(false)
  }

  const handleTrainingOptionChange = (trainingOption) => {
    setFormValues({ ...formValues, selectedtrainingOption: trainingOption })
  }

  const handleInjuredPartyOptionChange = (injuredOption) => {
    setFormValues({ ...formValues, selectedInjuredPartyOption: injuredOption })
  }

  const handlePotentialWitnessesChange = (potentialWitnessesOption) => {
    setFormValues({
      ...formValues,
      selectedPotentialWitnessesOptions: potentialWitnessesOption,
    })
  }

  const handleNumberYesOptionsChange = (numberOptions) => {
    setFormValues({ ...formValues, selectednumberYesOptions: numberOptions })
  }
  const handleQuantityOptionsChange = (quantityOptions) => {
    setFormValues({ ...formValues, selectedquantityOptions: quantityOptions })
  }
  const handleNumberNAOptionsChange = (numberNAOptions) => {
    setFormValues({ ...formValues, selectednumberNAOptions: numberNAOptions })
  }

  const [showDiv, setShowDiv] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value)
    setShowDiv(event.target.value === "training")
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size <= 10 * 1024 * 1024) {
      setFormValues({ ...formValues, selectedFile: file })
      setErrorMsg(false)
    } else {
      setErrorMsg(`${file.name} - File exceeds size limit`)
    }
  }

  const handleTextareaChange = (event) => {
    setFormValues({ ...formValues, inputValue: event.target.value })
  }

  const onNameChangeHandler = (event) => {
    setFormValues({ ...formValues, name: event.target.value })
  }

  const onInjuredPartyNameChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      injuredPartyName: event.target.value,
    })
  }

  const onInjuredPartyPhoneNoChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      injuredPartyPhoneNo: event.target.value,
    })
  }

  const onInjuredPartyEmailChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      injuredPartyEmail: event.target.value,
    })
  }

  const onPotentialWitnessesNameChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      potentialWitnessesName: event.target.value,
    })
  }

  const onPotentialWitnessesPhoneNoChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      potentialWitnessesPhoneNo: event.target.value,
    })
  }

  const onPotentialWitnessesEmailChangeHandler = (event) => {
    setFormValues({
      ...formValues,
      potentialWitnessesEmail: event.target.value,
    })
  }

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang)
    ClearData()
  }

  const characterCountLabel = `${formValues.inputValue.length} of 4000 ${t(
    "actionCardForm.label.maximum characters"
  )}`

  const characterCountLabelofName = `${formValues.name.length} of 45 ${t(
    "actionCardForm.label.maximum characters"
  )}`

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true);

    const bodyFormData = new FormData()
    bodyFormData.append("org", searchParams.get("org"))
    bodyFormData.append("afd", searchParams.get("afd"))
    bodyFormData.append("sted", searchParams.get("sted"))
    bodyFormData.append("nb_set", searchParams.get("nb_set"))
    bodyFormData.append("action_card_nb", searchParams.get("action_card_nb"))
    bodyFormData.append("version", searchParams.get("version"))
    bodyFormData.append("leverance", searchParams.get("leverance"))
    bodyFormData.append("year", searchParams.get("year"))
    bodyFormData.append("month", searchParams.get("month"))
    bodyFormData.append("type", searchParams.get("type"))
    bodyFormData.append("sharp", formValues.selected.value)
    bodyFormData.append("ac_documentation1", formValues.selectedFile)
    bodyFormData.append("qr_code", selectedOption)
    if (formValues.selectedtrainingOption?.value) {
      bodyFormData.append(
        "employee",
        formValues.selectedtrainingOption?.value == "other"
          ? formValues.name
          : formValues.selectedtrainingOption?.value
      )
    }
    bodyFormData.append(
      "numbers_yes",
      formValues.selectednumberYesOptions.value
    )
    bodyFormData.append("numbers_no", formValues.selectedquantityOptions.value)
    bodyFormData.append("numbers_na", formValues.selectednumberNAOptions.value)
    bodyFormData.append("comment", formValues.inputValue)
    bodyFormData.append(
      "injured_option",
      formValues.selectedInjuredPartyOption.value
    )
    bodyFormData.append(
      "witeness_option",
      formValues.selectedPotentialWitnessesOptions.value
    )
    bodyFormData.append("injured_option_name", formValues.injuredPartyName)
    bodyFormData.append("injured_option_phone", formValues.injuredPartyPhoneNo)
    bodyFormData.append("injured_option_email", formValues.injuredPartyEmail)
    bodyFormData.append(
      "witeness_option_name",
      formValues.potentialWitnessesName
    )
    bodyFormData.append(
      "witeness_option_phone",
      formValues.potentialWitnessesPhoneNo
    )
    bodyFormData.append(
      "witeness_option_email",
      formValues.potentialWitnessesEmail
    )
    bodyFormData.append("language", selectedLanguage)

    fetch(
      `${process.env.REACT_APP_API_URL}/send-registration-form/${id}`,
      {
        method: "POST",
        body: bodyFormData,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json()
        if (response.ok) {
          setSuccessfulSubmission(true)
          toast.success(data.message)
        }
        ClearData()
        setLoading(false);
        return Promise.reject(data.error)
      })
      .catch((error) => {
        toast.error(error)
        setLoading(false);
      })
  }

  const employeeData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL
      }/form-employee-list?id=${id}&acno=${searchParams.get("action_card_nb")}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
      .then(async (response) => {
        const data = await response.json()
        if (response.ok) {
          const empData = []
          const result = data.data.data
          result.map((one) => {
            empData.push({
              value: `${one.first_name} ${one.last_name ?? ""}`,
              label: `${one.first_name} ${one.last_name ?? ""}`,
            })
          })
          empData.unshift({ value: "Null", label: "--Select--" })
          // Add data to the end
          empData.push({ value: "Other", label: "--Other--" })
          const actioncard_name = {
            danish: data.data.action_name_danish,
            eng: data.data.action_name_eng,
          }
          setActionCardName(actioncard_name)
          setTrainingOptions(empData)
          setFormValues({
            ...formValues,
            selectedtrainingOption: empData[0],
          })
        }
        return Promise.reject(data.error)
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  useEffect(() => {
    employeeData()
  }, [])
  return (
    <>
      {successfulSubmission ? (
        <div className='container d-flex justify-content-center align-items-center vh-100'>
          <div className='text-center'>
            <img
              src='https://action-cards.com/wp-content/uploads/2022/05/Logo_positiv_CMYK_tagline_h%E2%94%9C%E2%95%95jre_EN_udvidet.svg'
              alt='logo'
              width='80%'
              style={{ backgroundColor: "transparent" }}
            />
            <hr />
            <h3>
              <b>Your data has been submitted</b>
            </h3>
            <h6>
              “Thank you–you have just helped to make your organization a safer
              place!”
            </h6>
          </div>
        </div>
      ) : (
        <>
          <div className='container justify-content-center mt-2'>
            <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className={`nav-link btn-dark ${selectedLanguage === "danish" ? "active" : ""
                    }`}
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='true'
                  onClick={() => handleLanguageChange("danish")}
                >
                  {t("IntroductionVideo.label.Danish")}
                </button>
              </li>

              <li className='nav-item' role='presentation'>
                <button
                  className={`nav-link btn-dark mx-3 ${selectedLanguage === "english" ? "active" : ""
                    }`}
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='false'
                  onClick={() => handleLanguageChange("english")}
                >
                  {t("IntroductionVideo.label.English")}
                </button>
              </li>
            </ul>
            <hr />

            <div className='box tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade'
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab'
              >
                <form onSubmit={handleSubmit}>
                  <div className='container mt-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <h4>
                          <b>You have scanned this ACTION-CARD: </b>
                        </h4>
                        <h5 className='mt-3'>
                          {selectedLanguage === "danish"
                            ? actioncardname?.danish
                            : actioncardname?.eng}
                        </h5>
                        <h6 className='mt-4'>
                          Press SEND to complete the registration.
                        </h6>
                        <h6 className='mt-4'>
                          <b>Real incident</b>
                        </h6>
                        <div className='mt-3'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            options={realIncidentOptions.map((option) => ({
                              value: option.value,
                              label: languageLabels[selectedLanguage][option.label],
                            }))}
                            onChange={handleChange}
                            value={formValues.selected}
                            autoFocus={true}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontSize: "18px", // Adjust the font size as needed
                              }),
                              option: (provided) => ({
                                ...provided,
                                fontSize: "18px", // Adjust the font size as needed
                              }),
                              // Add more styles as needed
                            }}
                          />
                        </div>

                        <>
                          <div className='mt-3'>
                            <h6>
                              <b>Enter your employee name or ID</b>
                            </h6>
                            <div className='w-50'>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={trainingOptions.map((option) => ({
                                  value: option.value,
                                  label: option.label,
                                }))}
                                onChange={handleTrainingOptionChange}
                                value={formValues.selectedtrainingOption}
                                autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>
                          </div>
                          {formValues.selectedtrainingOption?.value ===
                            "Other" && (
                              <div className='mt-3'>
                                <label forHtml='name'>
                                  <h6 className='radiolabel'>
                                    <b>
                                      Enter your Email ID
                                      <span className='danger'>*</span>
                                    </b>
                                  </h6>
                                </label>
                                <div>
                                  <input
                                    type='email'
                                    className='form-control'
                                    id='name'
                                    maxLength='45'
                                    value={formValues.name}
                                    style={{ width: "50%", color: "black" }}
                                    onChange={(e) => {
                                      onNameChangeHandler(e)
                                    }}
                                  />
                                </div>
                                <small className='mt-4'>
                                  {characterCountLabelofName}
                                </small>
                              </div>
                            )}
                        </>

                        {formValues.selected.value === "yes" && (
                          <div>
                            <div className='mt-3'>
                              <h6>
                                <b>
                                  To the injured party: would it be OK if we
                                  register your full name and contact details
                                  for documentation purposes?
                                </b>
                              </h6>
                              <span className='mt-5'>
                                <h6>
                                  The information is stored for a maximum of one
                                  year and is only used so that you yourself,
                                  upon request, can subsequently document this
                                  incident.
                                </h6>
                              </span>
                              <div className='w-full mt-3'>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  options={injuredParty.map((option) => ({
                                    value: option.value,
                                    label: languageLabels[selectedLanguage][option.label],
                                  }))}
                                  onChange={handleInjuredPartyOptionChange}
                                  value={formValues.selectedInjuredPartyOption}
                                  autoFocus={true}
                                  styles={{
                                    singleValue: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    // Add more styles as needed
                                  }}
                                />
                              </div>
                            </div>

                            {formValues.selectedInjuredPartyOption.value ===
                              "yes" && (
                                <div>
                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>
                                          First name and surname of injured party
                                        </b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyName}
                                        onChange={onInjuredPartyNameChangeHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Phone number for injured party</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyPhoneNo}
                                        onChange={
                                          onInjuredPartyPhoneNoChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Email address for injured party</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyEmail}
                                        onChange={
                                          onInjuredPartyEmailChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className='mt-3'>
                              <h6>
                                <b>
                                  For potential witness: would it be okay with
                                  you if we register your full name and contact
                                  details?
                                </b>
                              </h6>
                              <span className='mt-5'>
                                <h6>
                                  The information is stored for a maximum of one
                                  year and is only used in connection with any
                                  documentation of the accident.
                                </h6>
                              </span>
                              <div className='w-full mt-3'>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  options={potentialWitnesses.map((option) => ({
                                    value: option.value,
                                    label: languageLabels[selectedLanguage][option.label],
                                  }))}
                                  onChange={handlePotentialWitnessesChange}
                                  value={formValues.selectedPotentialWitnessesOptions}
                                  autoFocus={true}
                                  styles={{
                                    singleValue: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    // Add more styles as needed
                                  }}
                                />
                              </div>
                            </div>

                            {formValues.selectedPotentialWitnessesOptions
                              .value === "yes" && (
                                <div>
                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>First name and surname of witness</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.potentialWitnessesName}
                                        onChange={
                                          onPotentialWitnessesNameChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Phone number of witness</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={
                                          formValues.potentialWitnessesPhoneNo
                                        }
                                        onChange={
                                          onPotentialWitnessesPhoneNoChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Email of witness</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        value={formValues.potentialWitnessesEmail}
                                        style={{ color: "black" }}
                                        onChange={
                                          onPotentialWitnessesEmailChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}

                        {formValues.selected.value === "no" && (
                          <div>
                            <div className='mt-4'>
                              <h6>
                                <b>
                                  Is the QR code scanned to register for
                                  training or reading the Action-card?
                                  <span className='danger'>*</span>
                                </b>{" "}
                              </h6>
                            </div>
                            <div className='mt-4'>
                              <input
                                id='contactChoice1'
                                name='contact'
                                type='radio'
                                value='training'
                                required
                                checked={selectedOption === "training"}
                                onChange={handleRadioChange}
                              />
                              <label
                                for='contactChoice1'
                                className='radiolabel mx-2'
                                style={{ fontSize: "18px" }}
                              >
                                Yes, training
                              </label>
                              <br />
                              <input
                                type='radio'
                                id='contactChoice2'
                                name='contact'
                                value='read'
                                required
                                checked={selectedOption === "read"}
                                onChange={handleRadioChange}
                              />
                              <label
                                for='contactChoice2'
                                className='radiolabel mx-2'
                                style={{ fontSize: "18px" }}
                              >
                                Yes, read AC
                              </label>
                            </div>
                          </div>
                        )}

                        {/* <------------------------ Yes, training Options --------------------------> */}

                        {showDiv && (
                          <>
                            <div className='mt-3'>
                              <h6>
                                <b>Number of replies with answer Yes</b>
                                <span className='danger'>*</span>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster or instructor tells you the number of
                                replies with yes.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={numberYesOptions}
                                onChange={handleNumberYesOptionsChange}
                                value={formValues.selectednumberYesOptions}
                                autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>

                            <div className='mt-3'>
                              <h6>
                                <b>Number of replies with answer No</b>
                                <span className='danger'>*</span>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster or instructor tells you the number of
                                replies with no.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={quantityOptions}
                                onChange={handleQuantityOptionsChange}
                                value={formValues.selectedquantityOptions}
                                // autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>

                            <div className='mt-3'>
                              <h6>
                                <b>Number of replies with answer NA</b>
                                <span className='danger'>*</span>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster or instructor tells you the number of
                                NAs. NA means Not Applicable or Not Relevant.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={numberNAOptions}
                                onChange={handleNumberNAOptionsChange}
                                value={formValues.selectednumberNAOptions}
                                // autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* <------------------------ Yes, training Options --------------------------> */}

                        <div className='mt-4'>
                          <h6>
                            <b>
                              If possible, write a short note or comment on the
                              critical incident that you just have acted on.
                            </b>{" "}
                          </h6>
                        </div>
                        <div className='mt-4'>
                          <h6>
                            You can also write a comment about the training or
                            the ACTION-CARD here.
                          </h6>
                        </div>
                        <div className='mt-4 textarea'>
                          <textarea
                            name='input_11'
                            id='input_11'
                            className='textarea medium'
                            aria-describedby='gfield_description_61_11'
                            maxLength='4000'
                            aria-invalid='false'
                            rows='10'
                            cols='50'
                            style={{ fontSize: "16px" }}
                            value={formValues.inputValue}
                            onChange={handleTextareaChange}
                          ></textarea>
                        </div>
                        <small className='mt-4'>{characterCountLabel}</small>

                        <div className='mt-4'>
                          <h6>
                            <b>
                              If possible, upload a picture of the area where
                              the incident took place or other necessary
                              documentation.
                            </b>{" "}
                            Max. file size: 10 MB, Max. files: 10.
                          </h6>
                        </div>

                        <div className='form_drop_area'>
                          <span className='form_text'>Drop file here or</span>
                          <button className='form_button' type='button'>
                            <input
                              type='file'
                              accept='.jpg, .jpeg, .png'
                              onChange={handleFileChange}
                              id='upload1'
                              hidden
                            />
                            <label
                              className='label-color-white'
                              htmlFor='upload1'
                            >
                              CHOOSE FILES
                            </label>
                          </button>
                        </div>
                        <div>
                          {errorMsg ? (
                            <p className='form_text'>{errorMsg}</p>
                          ) : (
                            <div>
                              <span className='form_text'>
                                {formValues.selectedFile.name}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='mt-4'>
                          When you have filled in this form correctly, you must
                          press the "SEND" button and wait until "THANK YOU"
                          appears on the screen.
                        </div>
                        <div
                          className='mt-4'
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type='submit'
                            className='form_button'
                            style={{
                              padding: "8px 40px 8px 40px",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <span className="spinner" style={{ marginRight: '8px' }}></span> {/* Optionally, add a spinner icon */}
                                Loading...
                              </>
                            ) : (
                              'SEND'
                            )}

                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                className='tab-pane fade show active'
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                <form onSubmit={handleSubmit}>
                  <div className='container mt-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <h4>
                          <b>Du har scannet Action-cards®: </b>
                        </h4>
                        <h5 className='mt-3'>
                          {selectedLanguage !== "danish"
                            ? actioncardname.eng
                            : actioncardname.danish}
                        </h5>
                        <h6 className='mt-4'>
                          Tryk SEND for at fuldende registreringen.{" "}
                        </h6>
                        <h6 className='mt-4'>
                          <b>Skarp hændelse</b>
                        </h6>
                        <div className='mt-3'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            options={realIncidentOptions.map((option) => ({
                              value: option.value,
                              label: languageLabels[selectedLanguage][option.label],
                            }))}
                            onChange={handleChange}
                            value={formValues.selected}
                            autoFocus={true}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                fontSize: "18px", // Adjust the font size as needed
                              }),
                              option: (provided) => ({
                                ...provided,
                                fontSize: "18px", // Adjust the font size as needed
                              }),
                              // Add more styles as needed
                            }}
                          />
                        </div>

                        <>
                          <div className='mt-3'>
                            <h6>
                              <b>Angiv dit medarbejder navn eller ID</b>
                            </h6>
                            <div className='w-50'>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={trainingOptions.map((option) => ({
                                  value: option.value,
                                  label: option.label,
                                }))}
                                onChange={handleTrainingOptionChange}
                                value={formValues.selectedtrainingOption}
                                autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>
                          </div>
                          {formValues.selectedtrainingOption?.value ===
                            "Other" && (
                              <div className='mt-3'>
                                <label forHtml='name'>
                                  <h6 className='radiolabel'>
                                    <b>Skriv dit e-mail-id her</b>
                                    <span className='danger'>*</span>
                                  </h6>
                                </label>
                                <div>
                                  <input
                                    type='email'
                                    className='form-control'
                                    id='name'
                                    maxLength='45'
                                    value={formValues.name}
                                    style={{ width: "50%", color: "black" }}
                                    onChange={onNameChangeHandler}
                                  />
                                </div>
                                <small className='mt-4'>
                                  {characterCountLabelofName}
                                </small>
                              </div>
                            )}
                        </>

                        {formValues.selected.value === "yes" && (
                          <div>
                            <div className='mt-3'>
                              <h6>
                                <b>
                                  Til den tilskadekommende: Vil det være i orden
                                  med dig, at vi registrerer dit fulde navn og
                                  kontaktoplysninger?
                                </b>
                              </h6>
                              <span className='mt-5'>
                                <h6>
                                  Oplysningerne gemmes i højst 1 år og bruges
                                  kun til at du selv, ved forspørgelse,
                                  efterfølgende kan dokummenter denne hændelse.
                                </h6>
                              </span>
                              <div className='w-full mt-3'>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  options={injuredParty.map((option) => ({
                                    value: option.value,
                                    label: languageLabels[selectedLanguage][option.label],
                                  }))}
                                  onChange={handleInjuredPartyOptionChange}
                                  value={formValues.selectedInjuredPartyOption}
                                  styles={{
                                    singleValue: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    // Add more styles as needed
                                  }}
                                />
                              </div>
                            </div>

                            {formValues.selectedInjuredPartyOption.value ===
                              "yes" && (
                                <div>
                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Fornavn og efternavn </b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyName}
                                        onChange={onInjuredPartyNameChangeHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Telefonnummer</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyPhoneNo}
                                        onChange={
                                          onInjuredPartyPhoneNoChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Email</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.injuredPartyEmail}
                                        onChange={
                                          onInjuredPartyEmailChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className='mt-3'>
                              <h6>
                                <b>
                                  Til evt vidne: Vil det være i orden med dig,
                                  at vi registrerer dit fulde navn og
                                  kontaktoplysninger?
                                </b>
                              </h6>
                              <span className='mt-5'>
                                <h6>
                                  Oplysningerne gemmes i højst 1 år og bruges
                                  kun i forbindelse med evt. dokummentation af
                                  ulykken.
                                </h6>
                              </span>
                              <div className='w-full mt-3'>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  options={potentialWitnesses.map((option) => ({
                                    value: option.value,
                                    label: languageLabels[selectedLanguage][option.label],
                                  }))}
                                  onChange={handlePotentialWitnessesChange}
                                  value={formValues.selectedPotentialWitnessesOptions}
                                  styles={{
                                    singleValue: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      fontSize: "18px", // Adjust the font size as needed
                                    }),
                                    // Add more styles as needed
                                  }}
                                />
                              </div>
                            </div>

                            {formValues.selectedPotentialWitnessesOptions
                              .value === "yes" && (
                                <div>
                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Fornavn og efternavn</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.potentialWitnessesName}
                                        onChange={
                                          onPotentialWitnessesNameChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Telefonnummer</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={
                                          formValues.potentialWitnessesPhoneNo
                                        }
                                        onChange={
                                          onInjuredPartyPhoneNoChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className='mt-3'>
                                    <label forHtml='name'>
                                      <h6 className='radiolabel'>
                                        <b>Email</b>
                                        <span className='danger'>*</span>
                                      </h6>
                                    </label>
                                    <div>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        maxLength='45'
                                        style={{ color: "black" }}
                                        value={formValues.potentialWitnessesEmail}
                                        onChange={
                                          onInjuredPartyEmailChangeHandler
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}

                        {formValues.selected.value === "no" && (
                          <div>
                            <div className='mt-4'>
                              <h6>
                                <b>
                                  ER QR kode skannet for at registere træning
                                  eller læsning af Action-cardet?
                                </b>{" "}
                              </h6>
                            </div>
                            <div className='mt-4'>
                              <input
                                id='contactChoice1'
                                name='contact'
                                type='radio'
                                value='training'
                                checked={selectedOption === "training"}
                                onChange={handleRadioChange}
                              />
                              <label
                                for='contactChoice1'
                                className='radiolabel mx-2'
                                style={{ fontSize: "18px" }}
                              >
                                Ja, træning
                              </label>
                              <br />
                              <input
                                type='radio'
                                id='contactChoice2'
                                name='contact'
                                value='read'
                                checked={selectedOption === "read"}
                                onChange={handleRadioChange}
                              />
                              <label
                                for='contactChoice2'
                                className='radiolabel mx-2'
                                style={{ fontSize: "18px" }}
                              >
                                Ja, læst
                              </label>
                            </div>
                          </div>
                        )}

                        {/* <------------------------ Yes, training Options --------------------------> */}

                        {showDiv && (
                          <>
                            <div className='mt-3'>
                              <h6>
                                <b>Antal Ja *</b>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster eller instruktør fortæller dig
                                antallet af Ja’er.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={numberYesOptions}
                                onChange={handleNumberYesOptionsChange}
                                value={formValues.selectednumberYesOptions}
                                autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>

                            <div className='mt-3'>
                              <h6>
                                <b>Antal Nej</b>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster eller instruktør fortæller dig
                                antallet af Nej’er.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={quantityOptions}
                                onChange={handleQuantityOptionsChange}
                                value={formValues.selectedquantityOptions}
                                // autoFocus={true}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              />
                            </div>

                            <div className='mt-3'>
                              <h6>
                                <b>Antal NA</b>
                              </h6>
                              <h6 className='mt-3'>
                                Gamemaster eller instruktør fortæller dig
                                antallet af NA’er. NA betyder Not Applicable
                                eller Ikke Relevant.
                              </h6>
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                options={numberNAOptions}
                                onChange={handleNumberNAOptionsChange}
                                value={formValues.selectednumberNAOptions}
                                styles={{
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    fontSize: "18px", // Adjust the font size as needed
                                  }),
                                  // Add more styles as needed
                                }}
                              // autoFocus={true}
                              />
                            </div>
                          </>
                        )}

                        {/* <------------------------ Yes, training Options --------------------------> */}

                        <div className='mt-4'>
                          <h6>
                            <b>
                              Hvis muligt: Skriv en kort note eller kommentar
                              til den kritiske hændelse, som du netop har været
                              med til at handle på.
                            </b>{" "}
                          </h6>
                        </div>
                        <div className='mt-4'>
                          <h6>
                            Du kan også skrive en bemærkning til træningen eller
                            Action-cardet her.
                          </h6>
                        </div>
                        <div className='mt-4 textarea'>
                          <textarea
                            name='input_11'
                            id='input_11'
                            className='textarea medium'
                            aria-describedby='gfield_description_61_11'
                            maxLength='4000'
                            aria-invalid='false'
                            rows='10'
                            cols='50'
                            value={formValues.inputValue}
                            onChange={handleTextareaChange}
                            style={{ fontSize: "16px" }}
                          ></textarea>
                        </div>
                        <small className='mt-4'>{characterCountLabel}</small>

                        <div className='mt-4'>
                          <h6>
                            <b>Upload billede dokumentation</b> Max. file size:
                            10 MB, Max. files: 10.
                          </h6>
                        </div>

                        <div className='form_drop_area'>
                          <span className='form_text'>Slip fil her eller</span>
                          <button className='form_button' type='button'>
                            <input
                              type='file'
                              accept='.jpg, .jpeg, .png'
                              onChange={handleFileChange}
                              id='upload1'
                              hidden
                            />
                            <label
                              className='label-color-white'
                              htmlFor='upload1'
                            >
                              Vælg filer
                            </label>
                          </button>
                        </div>
                        <div>
                          {errorMsg ? (
                            <p className='form_text'>{errorMsg}</p>
                          ) : (
                            <div>
                              <span className='form_text'>
                                {formValues.selectedFile.name}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='mt-4'>
                          <h6>
                            Når du har udfyldt denne form korrekt, så skal du
                            trykke på knappen ”SEND” og vente til der står
                            ”TAK”, på skærmen.
                          </h6>
                        </div>
                        <div
                          className='mt-4'
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type='submit'
                            className='form_button'
                            style={{
                              padding: "8px 40px 8px 40px",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <span className="spinner" style={{ marginRight: '8px' }}></span>
                                Loading...
                              </>
                            ) : (
                              'SEND'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ActionCardsForm
