import React from 'react';

const Footer = () => {

  return (
    <footer className="">
      <div className='my-3'>
      </div>
    </footer>
  );
};

export default Footer;
