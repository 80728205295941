import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const AccessoriesTransactionList = () => {
  const page = 1;
  const itemsPerPage = 10;

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [accessories, setAccessories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const jwtToken = localStorage.getItem("jwt_token");
  const [filterInput, setFilterInput] = useState("");

  const location = useLocation();
  const id = location.search.split("?id=")[1];

  const TransactionAccessoriesList = async (page) => {
    fetch(`${process.env.REACT_APP_API_URL}/accessories/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          setAccessories(data.data.accessories);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
          setLoading(false);
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      TransactionAccessoriesList();
    }, 500);
    return () => clearTimeout(getData);
  }, [filterInput]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    TransactionAccessoriesList(event.selected + 1);
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col">Transaction Accessories</div>
            <div className="col">
              <span className="mr-2">Search and filters:</span>
              <input
                type="text"
                placeholder="E.g. Filter for “Name, Email & Customer ID"
                value={filterInput}
                onChange={(e) => {
                  setFilterInput(e.target.value);
                }}
              />
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">Quantity</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {accessories.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.date}</td>
                    <td>{item.type.toUpperCase()}</td>
                    <td>{item.quantity}</td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `/accessories/update-accessories`,
                            search: `?accessorie_id=${id}&id=${item.id}`,
                            state: { rowData: item },
                          });
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
              <tr hidden={accessories.length !== 0}>
                <td colSpan="5" className="text-center">
                  Data Not Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link bg_color"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default AccessoriesTransactionList;
