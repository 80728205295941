import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

const AdminPermissionList = () => {
  const [org, setOrg] = useState([])
  const jwtToken = localStorage.getItem("jwt_token")
  const { search } = useLocation()
  const paramIdSeprator = (param) => {
    const paramId = param.split("=")
    return paramId[1]
  }
  const id = paramIdSeprator(search)
  const OrganisationList = async () => {
    const id = paramIdSeprator(search)
    let url = `${process.env.REACT_APP_API_URL}/people/${id}`
    await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        const codeMessage = data.data.people_details.organisation_and_role
        if (codeMessage) {
          setOrg(codeMessage)
          console.log(codeMessage)
        } else {
          setOrg([])
        }
      })
  }

  //
  const deleteOrganazation = async (id) => {
    console.log(id)
    let url = `${process.env.REACT_APP_API_URL}/delete-organization/${id}`
    await fetch(url, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data) {
          toast.success(data.message)
          OrganisationList()
        } else {
          toast.success(data.error)
        }
      })
  }
  useEffect(() => {
    OrganisationList()
  }, [])
  return (
    <>
      <div className='row'>
        <div className='col-6'>Admin Permission List</div>
        <div className='col-6 d-flex justify-content-end'>
          <Link to={`/admin-person/permission-control?id=${id}`}>
            <button className='btn bg_color m-2' type='text'>
              Manage Permission
            </button>
          </Link>
        </div>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>ID</th>
            <th scope='col'>Organisation</th>
            <th scope='col'>Edit</th>
            <th scope='col'>Delete</th>
          </tr>
        </thead>
        <tbody>
          {org &&
            org.map((item, i) => {
              return (
                <tr key={item.id}>
                  <th>{item.people_role_id}</th>
                  <td scope='row'>{item.organisation_name}</td>
                  <td className='d-flex'>
                    <Link
                      to={`/admin-Person/permission-control?id=${id}&people_id=${item.people_role_id}`}
                    >
                      <button className='btn btn-lg bg_color '>
                        <i className='nav-icon fas fa-eye'></i>
                      </button>
                    </Link>
                  </td>
                  {/* DELETE BUTTION HIDE RIGHT NOW  */}
                  <td>
                    <button
                      className='btn btn-lg bg_color '
                      onClick={() => {
                        deleteOrganazation(item.id)
                      }}
                    >
                      <i className='nav-icon fas fa-trash'></i>
                    </button>
                  </td>
                </tr>
              )
            })}
          <tr hidden={org.length !== 0}>
            <td colSpan='5' className='text-center'>
              No Data Found
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default AdminPermissionList
