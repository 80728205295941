import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { filter } from "lodash";

const Shipment = () => {
  const [shipment, setShipment] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const jwtToken = localStorage.getItem("jwt_token");
  const [filterInput, setFilterInput] = useState("");

  const GetShipment = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/shipment?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data;
        if (codeMessage) {
          setShipment(codeMessage.data);
          setPageCount(Math.ceil(codeMessage.count / itemsPerPage));
          setLoading(false);
        } else {
          response.statusText == 404;
          setLoading(false);
        }
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    GetShipment(event.selected + 1);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      GetShipment(currentPage);
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [filterInput]);
  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row ">
            <div className="col-4">Shipment table</div>
            <div className="col-4">
              <span className="mr-2">Search and filters:</span>
              <input
                type="text"
                placeholder="E.g. Filter for Organisation name, Street Address"
                value={filterInput}
                onChange={(e) => {
                  setFilterInput(e.target.value);
                }}
              />
            </div>
            <div className="col-4 d-flex justify-content-end">
              <Link to="/shipment/add-shipment">
                <button type="button" className="btn responsive-btn bg_color mb-3 mt-1">
                  Add Shipment
                </button>
              </Link>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Batch</th>
                <th scope="col">Count</th>
                <th scope="col">First Set</th>
                <th scope="col">Last Set</th>
                <th scope="col">PurposeType</th>
                <th scope="col">SetID</th>
                <th scope="col">Organisation Name</th>
                <th scope="col">Country</th>
                <th scope="col">Street Addres</th>
                <th scope="col">Additional Information</th>
                <th scope="col">Postcode</th>
                <th scope="col">City</th>
                <th scope="col">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {shipment.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      {i + 1 + itemsPerPage * (currentPage - 1)}
                    </th>
                    <td>{item.batch_no}</td>
                    <td>{item.count}</td>
                    <td>{item.start}</td>
                    <td>{item.end}</td>
                    <td>{item.purpose_type}</td>
                    <td>{item.prototype_set_id}</td>
                    <td>{item.name}</td>
                    <td>{item.country}</td>
                    <td>{item.street_address}</td>
                    <td>{item.additional_street_info}</td>
                    <td>{item.postcode}</td>
                    <td>{item.city}</td>
                    <td>{item.remarks}</td>
                  </tr>
                );
              })}
              <tr hidden={shipment.length !== 0}>
                <td colSpan="5" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={0}
      />
    </>
  );
};

export default Shipment;
