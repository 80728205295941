import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ClientType = [
  { value: "Business", label: "Business" },
  { value: "Sport", label: "Sport" },
  { value: "Private", label: "Private" },
];

const Purpose = [
  { value: "First Aid", label: "First Aid" },
  { value: "Emergency Service", label: "Emergency Service" },
  { value: "Cyber", label: "Cyber" },
  { value: "Machines", label: "Machines" },
];

const Lang = [
  { value: "Danish", label: "Danish" },
  { value: "English", label: "English" },
];

const Format = [
  { value: "A6", label: "A6" },
  { value: "A7", label: "A7" },
  { value: "Tailored", label: "Tailored" },
];

const Standard = [
  { value: "Standard", label: "Standard" },
  { value: "Tailored", label: "Tailored" },
];

const Country = [
  { value: "Denmark", label: "Denmark" },
  { value: "US", label: "US" },
  { value: "UK", label: "UK" },
  { value: "Canada", label: "Canada" },
  { value: "Australia", label: "Australia" },
  { value: "South Africa", label: "South Africa" },
  { value: "New Zealand", label: "New Zealand" },
];

const AddSingleActionCard = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };
  const [countrys, setCountrys] = useState(Country[0]);
  const [clientTypes, setClientTypes] = useState(ClientType[0]);
  const [standards, setStandards] = useState(Standard[0]);
  const [formats, setFormats] = useState(Format[0]);
  const [purposes, setPurposes] = useState(Purpose[0]);
  const [langs, setLangs] = useState(Lang[0]);
  const [remark, setRemark] = useState("");
  const [versionNumber, setVersionNumber] = useState(0);
  const [pdfData, setPdfData] = useState([]);
  const [ac_no, setAc_no] = useState();
  const [files, setFiles] = useState([]);
  const [engName, setEngName] = useState("");
  const [engShortName, setEngShortName] = useState("");
  const [danishName, setDanishName] = useState("");
  const [danishShortName, setDanishShortName] = useState("");
  const [data, setData] = useState({
    english_name: [],
    english_short_name: [],
    danish_name: [],
    danish_short_name: [],
    client_type: [],
    target_company: [],
    format: [],
    purpose_type: [],
    language: [],
    standard_or_tailored: [],
    ac_document: [],
    remarks: [],
    version: 0,
  });
  // get api
  const jwtToken = localStorage.getItem("jwt_token");
  const Single = async () => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        // console.log(codeMessage);
        if (codeMessage) {
          const initialData = {
            client_type: [],
            target_company: [],
            format: [],
            purpose_type: [],
            language: [],
            standard_or_tailored: [],
            ac_document: [],
            remarks: [],
            version: 0,
          };
          codeMessage.map((one) => {
            one.client_type &&
              initialData.client_type.push({
                label: one.client_type,
                value: one.client_type,
              });
            one.target_company &&
              initialData.target_company.push({
                label: one.target_company,
                value: one.target_company,
              });
            one.format &&
              initialData.format.push({
                label: one.format,
                value: one.format,
              });
            one.purpose_type &&
              initialData.purpose_type.push({
                label: one.purpose_type,
                value: one.purpose_type,
              });
            one.language &&
              initialData.language.push({
                label: one.language,
                value: one.language,
              });
            one.standard_or_tailored &&
              initialData.standard_or_tailored.push({
                label: one.standard_or_tailored,
                value: one.standard_or_tailored,
              });
            one.ac_document &&
              initialData.ac_document.push({
                label: one.ac_document,
                value: one.ac_document,
              });
            one.remarks &&
              initialData.remarks.push({
                label: one.remarks,
                value: one.remarks,
              });
            initialData.version = one.version;
          });
          setData(initialData);
          setVersionNumber(initialData.version);
        }
      });
  };
  //
  useEffect(() => {
    Single();
    AcNumberInAddData();
    const id = paramIdSeprator(search);
    if (id) {
      update(id);
    }
  }, []);
  // post api for unit
  const postDataApi = async () => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard`;
    const bodyFormData = new FormData();
    const returnLabel = (arr) => {
      return arr.map((one) => {
        return one.label;
      });
    };
    bodyFormData.append("english_name", engName);
    bodyFormData.append("english_short_name", engShortName);
    bodyFormData.append("danish_name", danishName);
    bodyFormData.append("danish_short_name", danishShortName);
    bodyFormData.append(
      "client_type",
      Array.isArray(clientTypes)
        ? JSON.stringify(returnLabel(clientTypes))
        : JSON.stringify([clientTypes.label])
    );
    bodyFormData.append(
      "target_country",
      Array.isArray(countrys)
        ? JSON.stringify(returnLabel(countrys))
        : JSON.stringify([countrys.label])
    );
    bodyFormData.append(
      "format",
      Array.isArray(formats)
        ? JSON.stringify(returnLabel(formats))
        : JSON.stringify([formats.label])
    );
    bodyFormData.append("purpose_type", purposes?.label);
    bodyFormData.append(
      "language",
      Array.isArray(langs)
        ? JSON.stringify(returnLabel(langs))
        : JSON.stringify([langs.label])
    );
    bodyFormData.append("standard_or_tailored", standards?.label);
    bodyFormData.append("version", versionNumber);
    files.length &&
      Array.from(files).forEach((file) => {
        bodyFormData.append("ac_document", file);
      });
    bodyFormData.append("remarks", remark);
    if (ac_no) {
      bodyFormData.append("ac_no", ac_no);
    }
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: bodyFormData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          toast.success("Data Added");
          history.push('/single-action')
        }
      });
  };
  // get api
  //
  const id = paramIdSeprator(search);
  const update = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        setPdfData(codeMessage.version_pdf_file);
        setEngName(codeMessage.english_name)
        setEngShortName(codeMessage.english_short_name)
        setDanishName(codeMessage.danish_name)
        setDanishShortName(codeMessage.danish_short_name)
        setRemark(codeMessage.remarks);
        setStandards({
          label: codeMessage.standard_and_tailored,
          value: codeMessage.standard_and_tailored,
        });
        const filteredClientData = ClientType.filter((one) => {
          return codeMessage.client_type.includes(one.label);
        });
        const filteredCountry = Country.filter((one) => {
          return codeMessage.target_company.includes(one.label);
        });
        const filteredFormate = Format.filter((one) => {
          return codeMessage.format.includes(one.label);
        });
        const filteredLen = Lang.filter((one) => {
          return codeMessage.language.includes(one.label);
        });
        // client_type
        setClientTypes(filteredClientData);
        // target_company
        setCountrys(filteredCountry);
        // formats
        setFormats(filteredFormate);
        // purpose_type
        // language
        setLangs(filteredLen);
        setPurposes({
          label: codeMessage.purpose_type,
          value: codeMessage.purpose_type,
        });
        setAc_no(codeMessage.ac_no);
      });
  };
  // update data
  const UpdateDataApi = async () => {
    const id = paramIdSeprator(search);
    let url = `${process.env.REACT_APP_API_URL}/actioncard/${id}`;
    const bodyFormData = new FormData();
    const returnLabel = (arr) => {
      return arr.map((one) => {
        return one.label;
      });
    };
    bodyFormData.append("english_name", engName);
    bodyFormData.append("english_short_name", engShortName);
    bodyFormData.append("danish_name", danishName);
    bodyFormData.append("danish_short_name", danishShortName);
    bodyFormData.append(
      "client_type",
      Array.isArray(clientTypes)
        ? JSON.stringify(returnLabel(clientTypes))
        : JSON.stringify([clientTypes.label])
    );
    bodyFormData.append(
      "target_country",
      Array.isArray(countrys)
        ? JSON.stringify(returnLabel(countrys))
        : JSON.stringify([countrys.label])
    );
    bodyFormData.append(
      "format",
      Array.isArray(formats)
        ? JSON.stringify(returnLabel(formats))
        : JSON.stringify([formats.label])
    );
    bodyFormData.append("purpose_type", purposes?.label);
    bodyFormData.append(
      "language",
      Array.isArray(langs)
        ? JSON.stringify(returnLabel(langs))
        : JSON.stringify([langs.label])
    );
    bodyFormData.append("purpose_type", purposes?.label);
    bodyFormData.append("standard_or_tailored", standards?.label);
    files.length &&
      Array.from(files).forEach((file) => {
        bodyFormData.append("ac_document", file);
      });
    bodyFormData.append("version", versionNumber);
    bodyFormData.append("remarks", remark);
    bodyFormData.append("ac_no", ac_no);
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: bodyFormData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          toast.success("Data Updated Success");
          history.push('/single-action')
        }
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      UpdateDataApi();
    } else {
      postDataApi();
    }
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    const tempList = [...files, ...fileList];
    setFiles(tempList);
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const AcNumberInAddData = async () => {
    let url = `${process.env.REACT_APP_API_URL}/ac-number`;
    await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data;
        setAc_no(codeMessage);
      });
  };
  return (
    <>
      {paramIdSeprator(search) ? "Update" : "Add"} ActionCard
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="englishname">
              English Name:
            </label>
            <input className="form-control"
              id="englishname"
              value={engName}
              type="text"
              onChange={(e) => setEngName(e.target.value)}
            />
            <label className="mt-1" htmlFor="englishshortname">
              English short name:
            </label>
            <input className="form-control"
              id="englishshortname"
              value={engShortName}
              type="text"
              onChange={(e) => setEngShortName(e.target.value)}
            />
            <label className="mt-1" htmlFor="danishname">
              Danish name:
            </label>
            <input className="form-control"
              id="danishname"
              value={danishName}
              type="text"
              onChange={(e) => setDanishName(e.target.value)}
            />
            <label className="mt-1" htmlFor="setDanishShortName">
              Danish short name:
            </label>
            <input className="form-control"
              id="setDanishShortName"
              value={danishShortName}
              type="text"
              onChange={(e) => setDanishShortName(e.target.value)}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Client Type:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={ClientType ? clientTypes : []}
              name="color"
              options={ClientType}
              onChange={setClientTypes}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Target Country:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Country ? countrys : []}
              name="color"
              options={Country}
              onChange={setCountrys}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Format:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Format ? formats : []}
              name="color"
              options={Format}
              onChange={setFormats}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Purpose Type:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={Purpose ? purposes : []}
              name="color"
              options={Purpose}
              onChange={setPurposes}
            />
          </div>
          {/* Right  */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="inputEmail4">
              Lang:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Lang ? langs : []}
              name="color"
              options={Lang}
              onChange={setLangs}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Standard/Tailored:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={Standard ? standards : []}
              name="color"
              options={Standard}
              onChange={setStandards}
            />
            <label className="mt-1" htmlFor="version_number">
              {/* Version no. and AC-pdf file: */}
              Version no
            </label>
            <input
              type="text"
              className="form-control"
              id="version_number"
              step="0.1"
              onChange={(e) => {
                setVersionNumber(e.target.value);
              }}
              value={versionNumber}
            />
            <br></br>
            <label className="mt-1" htmlFor="formFile">
              AC-pdf file
            </label>
            <div>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="form-control"
              />
              {files.length ? (
                <div className="border mt-3 d-flex flex-wrap">
                  {files.map((file, index) => (
                    <div className="ml-3 mr-5 my-1">
                      <div
                        className="border p-1 bg-muted rounded"
                        style={{ backgroundColor: "#e6e6e6" }}
                      >
                        {file.name}{" "}
                        <button
                          type="button"
                          className="btn-sm p-0 px-1 m-1 border-0"
                          style={{ backgroundColor: "#e6e6e6" }}
                          onClick={() => handleRemove(index)}
                        >
                          <b>X</b>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <label className="mt-1" htmlFor="ac_no">
              Ac No:
            </label>
            <input
              type="number"
              className="form-control"
              id="ac_no"
              onChange={(e) => {
                setAc_no(e.target.value);
              }}
              value={ac_no}
            />
            <label className="mt-1" htmlFor="remarks">
              Remarks:
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              value={remark}
            />
            <button type="Submit" className="btn m-3 bg_color">
              {paramIdSeprator(search) ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSingleActionCard;