import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";

const Standard = [
  { value: "All ", label: "All" },
  { value: "Format ", label: "Format" },
  { value: "English Set Name", label: "English Set Name" },
  { value: "Target Company", label: "Target Company" },
];

const Inventory = () => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [inventory, setInventory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const jwtToken = localStorage.getItem("jwt_token");
  const [filterInputFormat, setFilterInputFormat] = useState("");
  const [filterInputSetName, setFilterInputSetName] = useState("");
  const [filterInputCountry, setFilterInputCountry] = useState("");

  const inventoryList = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/inventory?page=${page}&limit=${itemsPerPage}`;
    if (filterInputFormat) {
      url += `&format=${filterInputFormat}`;
    }
    if (filterInputSetName) {
      url += `&english_set_name=${filterInputSetName}`;
    }
    if (filterInputCountry) {
      url += `&target_company=${filterInputCountry}`;
    }

    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data;
        if (codeMessage) {
          setInventory(codeMessage.invenory);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
          setLoading(false);
        } else {
          // toast.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    inventoryList(event.selected + 1);
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      inventoryList(currentPage);
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [filterInputFormat, filterInputSetName, filterInputCountry]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row mb-3 d-flex justify-content-center">
            <div className="col-2 col-lg-3 col-md-6 col-sm-12 mb-3">
              Inventory
            </div>
            <div className="col-3 col-lg-3 col-md-6 col-sm-1">
              <input
                type="text"
                className="filter-width"
                placeholder="E.g. Filter for Format"
                value={filterInputFormat}
                style={{
                  height: "37px",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "5px",
                }}
                onChange={(e) => {
                  setFilterInputFormat(e.target.value);
                }}
              />
            </div>
            <div className="col-3 col-lg-3 col-md-6 col-sm-12">
              <input
                type="text"
                className="filter-width"
                placeholder="E.g. Filter for English Set Name"
                value={filterInputSetName}
                style={{
                  height: "37px",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "5px",
                }}
                onChange={(e) => {
                  setFilterInputSetName(e.target.value);
                }}
              />
            </div>
            <div className="col-3 col-lg-3 col-md-6 col-sm-12">
              <input
                type="text"
                className="filter-width"
                placeholder="E.g. Filter for Target country"
                value={filterInputCountry}
                style={{
                  height: "37px",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "5px",
                }}
                onChange={(e) => {
                  setFilterInputCountry(e.target.value);
                }}
              />
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">English Set Name</th>
                <th scope="col">Target country</th>
                <th scope="col">Format</th>
                <th scope="col">Purpose Type</th>
                <th scope="col">Lang.</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Set IDs</th>
              </tr>
            </thead>
            <tbody>
              {inventory &&
                inventory.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.english_set_name}</td>
                      <td>
                        {JSON.parse(item.target_company ?? "[]").join(", ")}
                      </td>
                      <td>{JSON.parse(item.format ?? "[]").join(", ")}</td>
                      <td>{item.purpose_type}</td>
                      <td>{JSON.parse(item.language ?? "[]").join(", ")}</td>
                      <td>{item.start}</td>
                      <td>{item.end}</td>
                      <td>{item.set_id}</td>
                    </tr>
                  );
                })}
              <tr hidden={inventory.length !== 0}>
                <td colSpan="5" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={0}
      />
    </>
  );
};

export default Inventory;
