import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Checkbox from "./Checkbox";
import { toast } from "react-toastify";
// import { CheckboxData as Catalogues } from "./CheckboxData";

const AdminPermission = () => {
  const { search } = useLocation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [action, setAction] = useState([]);
  const [permissionType, setPermissionType] = useState([]);
  const [action2, setAction2] = useState(permissionType.length > 0 ? permissionType[0] : null);
  const [action1, setAction1] = useState([]);
  const [select, setSelect] = useState(action[0]);
  // const [checkboxListUpdated, setCheckboxListUpdated] = useState(false);
  const jwtToken = localStorage.getItem("jwt_token");
  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };
  const paramIdSeprator2 = (param) => {
    const paramId = param.split("=");
    return paramId[2];
  };
  const id = paramIdSeprator(search);
  const data = async () => {
    let url = `${process.env.REACT_APP_API_URL}/organisations`;
    const res = fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        const codeMessage = data.data.organizations;
        codeMessage.map((one) => {
          (one.value = one.id), (one.label = one.name);
        });
        people_permission_list(codeMessage)
        setAction(codeMessage);
        return codeMessage;
      });
    return res;
  };
  useEffect(() => {
    async function fetchData() {
      await data();
    }
    fetchData();
  }, []);

  // const add = (data) => {
  const add = () => {
    let url = `${process.env.REACT_APP_API_URL}/role-data`;
    const id = paramIdSeprator(search);

    const checkBoxTickData = action1
      .map((item, index) => (item.checked ? index : null))
      .filter((index) => index !== null);
    const body = {
      organization_id: select ? select.id : 0,
      // checkbox: data.join(),
      checkbox: checkBoxTickData.length > 0 ? checkBoxTickData.join(',') : '',
      people_id: +col_id,
      type: action2.type
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);

        } else {
          toast.success("Data Added");
        }
      });
  };
  //
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    const initialData = [...action1];
    initialData.map((e) => {
      e.checked = !isCheckAll;
    });
    setAction1(initialData);
  };
  const people_permission_list = async (org_list) => {
    let url = `${process.env.REACT_APP_API_URL}/people-permission`;
    await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const codeMessage = data.data;
        if (codeMessage) {
          codeMessage.forEach((one) => {
            one.value = one.id;
            one.label = one.type;
          });
          localStorage.setItem('myArray', JSON.stringify(codeMessage))
          setPermissionType(codeMessage);
          update(org_list, codeMessage)
        }
      });
  };
  const onSubmitHandler = (e) => {
    // checkbox data show here
    const checkedArr = action1
      .filter((data) => {
        if (data.checked === true) {
          return data.id;
        }
      })
      .map((one) => {
        return one.id;
      });
    e.preventDefault();
    // update api
    if (col_id && people_id) {
      let url = `${process.env.REACT_APP_API_URL}/update-role-data`;
      const checkBoxTickData = action1
        .map((item, index) => (item.checked ? index : null))
        .filter((index) => index !== null);
      const body = {
        // checkbox: checkedArr.join(),
        checkbox: checkBoxTickData.length > 0 ? checkBoxTickData.join(',') : '',
        id: +people_id,
        people_id: +col_id,
        organisation_id: select ? select[0].id : 0,
        type: action2.type
      };
      fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const codeMessage = data;
          if (data.error !== null) {
            toast.error("Something went wrong!!!");
          } else {
            toast.success("Data Added");
          }
        });
    } else {
      add(checkedArr);
      // ClearData();
    }
  };

  const onCheckboxHandler = (e, index) => {
    const initialData = [...action1];
    initialData[index].checked = e.target.checked
    setAction1(initialData);
  };

  const setCheckboxData = (checkedArray) => {
    const initialData = [...action1];
    initialData.map((one) => {
      if (checkedArray?.includes(one.id)) {
        one.checked = true;
      }
    });
    setAction1(initialData);
  };

  const onChangePermission = (e) => {
    setAction2(e)
    if (e?.permissions_list.length !== 0) {
      const data = e?.permissions_list ? e?.permissions_list.map((one) => { return { one, checked: true } }) : [];
      setAction1(data);
    }
  }
  // get data for auto fill

  const people_id = paramIdSeprator2(search);
  const col_id = id.split("&")[0];
  const update = async (org_list, permissions) => {
    let url = `${process.env.REACT_APP_API_URL}/update-role-data/${people_id}/${col_id}`;
    await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        const codeMessage = data.data.organisation_id;

        const org_id = org_list.filter((one) => {
          return one.id == data.data.organisation_id
        })
        setSelect(org_id)
        const updatedCheckboxes = data.data.checkbox
          .split(",")
          .map((e) => parseInt(e));
        setCheckboxData(updatedCheckboxes);

        const typeSelect = permissions.filter((one) => {
          if (one.type === data.data.type) {
            return one
          }
        })
        setAction2(typeSelect[0].type)
        onChangePermission(typeSelect[0])
        const checkedPermissions = []
        typeSelect[0].permissions_list.forEach((e, i) => {
          checkedPermissions.push({
            one: e,
            checked: data.data.checkbox.includes(i)
          })
        })
        setAction1(checkedPermissions)
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-6">Permission Control</div>
      </div>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-6">
            <label className="mt-1" htmlFor="inputEmail4">
              Organisation:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={action ? select : []}
              name="permission"
              options={action ? action : []}
              onChange={setSelect}
            />
            <label className="mt-1" htmlFor="text">
              Account Type:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={permissionType ? action2 : []}
              options={permissionType}
              onChange={(e) => onChangePermission(e)}
            />
          </div>

          <div className="col-6">
            <h5>Add Features:</h5>
            {action2?.permissions_list && (
              <>
                <ul>

                  {
                    action2.permissions_list.map((one, index) => {
                      const words = one.split('_');
                      const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                      const finalList = formattedWords.join(' ')
                      return (
                        <div className="form-check">

                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="id"
                            checked={action1[index] ? action1[index].checked : false}
                            onChange={(e) => onCheckboxHandler(e, index)}
                          />
                          <label className="form-check-label" htmlFor={index}>
                            {finalList}
                          </label>
                        </div>)

                    })}
                </ul>
              </>
            )}




            <button type="Submit" className="btn m-3 bg_color">
              Update
            </button>
            {/* <button type="delete" className="btn m-3  bg_color">
              Delete
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default AdminPermission;
