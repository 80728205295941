import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

const Choice = [
  { value: "organisations", label: "Organisation" },
  { value: "private", label: "Private" },
];

const AddShipment = () => {
  const [choiceData, setChoiceData] = useState(Choice[0]);
  const [orgData, setOrgData] = useState([]);
  const [orgDropdownData, setOrgDropdownData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState();
  const [selectOrgValue, setSelectedOrgValue] = useState();
  const [actionCards, setActionCards] = useState("");



  // post API
  const PostData = () => {
    const jwtToken = localStorage.getItem("jwt_token");
    let url = `${process.env.REACT_APP_API_URL}/shipment`;
    const body = {
      orgnisation_id: selectedOrg.id,
      action_card_sets: actionCards.split(",")
    }
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error("Something went wrong!!!");
        } else {
          toast.success("Data Added");
        }
      });
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwt_token");
    let url = `${process.env.REACT_APP_API_URL}/${choiceData.value}`;
    return fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => {
      return res.json();
    }).then((data) => {
      const codeMessage = choiceData.value == "organisations" ? data.data.organizations : data.data.privates;
      setOrgData(codeMessage)
      setOrgDropdownData(codeMessage.map((one) => {
        return { value: one.id, label: one.name ?? one.email ?? "" }
      }));
      return codeMessage;
    });
  }, [choiceData.value]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    PostData();
  };

  return (
    <>
      <h3>Add Shipment</h3>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="id">
              Choose from either “1AF organisation” or “3AF private”:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={Choice ? choiceData : []}
              name="color"
              options={Choice}
              onChange={setChoiceData}
            />
            <label className="mt-1" htmlFor="batch">
              New (searchable) dropdown with either “organisations” or
              “private”:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={orgDropdownData ? selectOrgValue : []}
              name="color"
              options={orgDropdownData}
              onChange={(e) => {
                setSelectedOrgValue(e)
                const data = orgData.find(x => x.id === e.value)
                setSelectedOrg(data)
              }}
            />
            <label className="mt-1" htmlFor="firstName">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={selectedOrg?.name ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="email">
              Email address:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={selectedOrg?.email ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="phone_number">
              Phone number:
            </label>
            <input
              type="text"
              className="form-control"
              id="phone_number"
              value={selectedOrg?.phone_number ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="country">
              Country:
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={selectedOrg?.country ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="street_address">
              Street address:
            </label>
            <input
              type="text"
              className="form-control"
              id="street_address"
              value={selectedOrg?.street_address ?? ""}
              disabled={true}
            />

            <label className="mt-1" htmlFor="firstName">
              {selectedOrg !== undefined && selectedOrg?.unit_division?.length ? (
                selectedOrg?.unit_division.map((e) => (
                  <h4>
                    {e.actioncard_set_number
                      ? e.actioncard_set_number
                      : e.start + "-" + e.end}
                  </h4>
                ))
              ) : (
                <h4 className="mt-2">None</h4>
              )}
            </label>
          </div>

          {/* Right Side */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="additional_street_info">
              Additional street info:
            </label>
            <input
              type="text"
              className="form-control"
              id="additional_street_info"
              value={selectedOrg?.additional_street_info ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="city">
              City:
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={selectedOrg?.city ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="state">
              State/Province/Region:
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={selectedOrg?.state ?? ""}
              disabled={true}
            />
            <label className="mt-1" htmlFor="postcode">
              Postcode:
            </label>
            <input
              type="text"
              className="form-control"
              id="postcode"
              value={selectedOrg?.postcode ?? ""}
              disabled={true}
            />

            <label htmlFor="message-text" className="col-form-label">
              Actioncard Set Number
            </label>
            <input
              className="form-control"
              id="action-card-set"
              value={actionCards}
              onChange={(e) => {
                setActionCards(e.target.value);
              }}
            />

            <label className="mt-1" htmlFor="remarks">
              Remarks:
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              value={selectedOrg?.remarks ?? ""}
              disabled={true}
            />
            <button type="Submit" className="btn m-3 bg_color">
              Add Shipment
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddShipment;
