import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const Production = () => {
  const [productions, setProductions] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const jwtToken = localStorage.getItem("jwt_token");
  const history = useHistory();
  const [csvdata, setCsvdata] = useState([]);
  const [filterInput, setFilterInput] = useState("")


  const headers = [
    { label: "ID", key: "id" },
    { label: "Batch No", key: "batch_no" },
    { label: "Qr No", key: "qr_no" },
    { label: "Ownership", key: "name" },
    { label: "First Set", key: "start" },
    { label: "Last Set", key: "end" },
    { label: "Count", key: "count" },
    { label: "Proto SetID", key: "prototype_set_id" },
    { label: "Client Type", key: "client_type" },
    { label: "Target Country", key: "target_company" },
    { label: "Format", key: "format" },
    { label: "Set Name", key: "english_set_name" },
    { label: "Purpose Type", key: "purpose_type" },
    { label: "Lang", key: "language" },
    { label: "Year", key: "year" },
    { label: "Remarks", key: "remarks" },
  ];

  const GetProduction = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/production?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data;
        if (codeMessage) {
          setProductions(codeMessage.production);
          setPageCount(Math.ceil(codeMessage.count / itemsPerPage));
          setLoading(false);
        } else {
          response.statusText == 404;
          setLoading(false);
        }
      });
  };

  const getAllProductionData = async () => {
    const productionData = await fetch(
      "https://actioncards.azurewebsites.net/all-production",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    const response = await productionData.json();
    const initialData = response.data;

    const modifiedData = initialData.map((one) => {
      return {
        id: one?.id,
        batch_no: one?.batch_no,
        qr_no: one?.qr_no,
        name: one?.name,
        start: one?.start,
        end: one?.end,
        count: one?.count,
        prototype_set_id: one?.prototype_set_id,
        client_type: one?.client_type,
        target_company: JSON.parse(one?.target_company),
        format: JSON.parse(one?.format),
        english_set_name: one?.english_set_name,
        purpose_type: one?.purpose_type,
        language: JSON.parse(one?.language),
        year: one?.year,
        remarks: one?.remarks,
      };
    });

    setCsvdata(modifiedData);
    return () => { clearTimeout(timeOut) }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    GetProduction(event.selected + 1);
  };

  const downloadUsingLink = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = '';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const download_qr_code = async (item, index) => {
    setTimeout(async () => {
      let url = `${process.env.REACT_APP_API_URL}/qr-number-generated`;
      const res = await fetch(url, {
        method: "post",
        body: JSON.stringify(item),
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      })
      const data = await res.json();
      if (data.error) {
        toast.error(data.error);
      } else {
        downloadUsingLink(data.data);
        toast.success("Qr Code Downloaded Successfully");
      }
    }, index * 100);
  };


  useEffect(() => {
    getAllProductionData();
    let timeOut = setTimeout(() => {
      GetProduction(currentPage);
    }, 500);
    return () => {
      clearTimeout(timeOut);
    }
  }, [filterInput]);

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col">Production overview table</div>
            <div className="col">
              <span className="mr-2">Search and filters:</span>
              <input type="text" placeholder="E.g. Filter for “Ownership, Set numbers, Proto SetID, Set Name, Purpose Type" value={filterInput} onChange={(e) => { setFilterInput(e.target.value) }} />
            </div>
            <div className="col d-flex justify-content-end">
              <Link to="/production/add-production">
                <button type="button" className="btn bg_color m-2">
                  Add New Production (QR.Gen)
                </button>

              </Link>
              <CSVLink
                headers={headers}
                data={csvdata}
                filename="ProductionData"
                className={
                  csvdata.length
                    ? "btn bg_color m-2"
                    : "btn bg_color m-2 disabled"
                }
              >
                Download full table as CSV
              </CSVLink>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Batch No</th>
                <th scope="col">Qr No</th>
                <th scope="col">Ownership</th>
                <th scope="col">First Set</th>
                <th scope="col">Last Set</th>
                <th scope="col">Count</th>
                <th scope="col">Proto SetID</th>
                <th scope="col">Client Type</th>
                <th scope="col">Target Country</th>
                <th scope="col">Format</th>
                <th scope="col">Set Name</th>
                <th scope="col">Purpose Type</th>
                <th scope="col">Lang</th>
                <th scope="col">File</th>
                <th scope="col">Year</th>
                <th scope="col">Remarks</th>
                <th scope="col">Edit</th>
                <th scope="col">QR code Download</th>
              </tr>
            </thead>
            <tbody>
              {productions.map((item, index) => {
                return (
                  <tr key={item.start}>

                    <td>{item.batch_no}</td>
                    <td>{item.qr_no}</td>
                    <td>{item.name}</td>
                    <td>{item.start}</td>
                    <td>{item.end}</td>
                    <td>{item.count}</td>
                    <td>{item.prototype_set_id}</td>
                    <td>{item.client_type}</td>
                    <td>
                      {JSON.parse(item.target_company ?? "[]").join(", ")}
                    </td>
                    <td>{JSON.parse(item.format ?? "[]").join(", ")}</td>
                    <td>{item.english_set_name}</td>
                    <td>{item.purpose_type}</td>
                    <td>{JSON.parse(item.language ?? "[]").join(", ")}</td>
                    <td>Yes</td>
                    <td>{item.year}</td>
                    <td>{item.remarks}</td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `/production/add-production`,
                            search: `?id=${item.id}`,
                          });
                        }}
                      ></i>
                    </td>
                    <td><i
                      type="button"
                      className="nav-icon fas fa-solid fa-download btn-lg bg_color"
                      onClick={() => download_qr_code({
                        "first_set": item.start,
                        "id": item.id,
                        "last_set": item.end,
                        "year": item.year,
                        "qr_no": item.qr_no,
                        "format": JSON.parse(item.format ?? "[]").join(", "),
                        "target_country": JSON.parse(item.target_company ?? "[]").join(", ")
                      }, index)}
                    ></i></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={0}
      />
    </>
  );
};

export default Production;
