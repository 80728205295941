import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

const BuyAccessories = () => {
  const history = useHistory();
  const { search } = useLocation();
  const jwtToken = localStorage.getItem("jwt_token");

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/accessories-drop-down`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          const mappedOptions = data.data.map((option) => {
            return {
              label: option.ac_accessoriesid,
              value: option.id,
            };
          });
          setOptions(mappedOptions);
        }
        return Promise.reject(data.error);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  const [accessoriesList, setAccessoriesList] = useState({
    item: "",
    date: "",
    quantity: "",
  });

  const GetAccessoriesListById = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/accessories/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const accessoriesList = data.data;
        setAccessoriesList({
          item: accessoriesList.id,
          date: accessoriesList.date,
          quantity: accessoriesList.quantity,
        });
      });
  };

  useEffect(() => {
    if (search) {
      const id = paramIdSeprator(search);
      GetAccessoriesListById(id);
    }
  }, []);

  const onChangeHandler = (e) => {
    let tempObj = { ...accessoriesList };
    tempObj[e.target.id] = e.target.value;
    setAccessoriesList(tempObj);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_API_URL}/buy-accessories`;
    const body = {
      accessoriesid: selectedOption.value,
      date: accessoriesList.date,
      quantity: accessoriesList.quantity,
      type: "Buy",
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          history.push("/accessories");
          toast.success(data.message);
          ClearData();
        }
      });
  };

  const ClearData = (e) => {
    setAccessoriesList({
      item: "",
      date: "",
      quantity: "",
    });
  };

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" forHtml="item">
              Item :
            </label>
            <Select
              lassName="basic-single"
              classNamePrefix="select"
              value={selectedOption}
              id="item"
              name="color"
              options={options}
              onChange={handleSelectChange}
            />
            <label className="mt-1" forHtml="date">
              Bought Date:
            </label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={accessoriesList.date}
              onChange={onChangeHandler}
            />
            <label className="mt-1" forHtml="quantity">
              Quantity
            </label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              value={accessoriesList.quantity}
              onChange={onChangeHandler}
            />
            <button type="submit" className="btn mt-3 bg_color">
              Buy
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default BuyAccessories;
