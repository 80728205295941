import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AddListOfAccessories = () => {
  const history = useHistory();
  const { search } = useLocation();
  const jwtToken = localStorage.getItem("jwt_token");

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const location = useLocation();
  const rowData = location.state?.rowData;

  const [accessoriesList, setAccessoriesList] = useState({
    id: "",
    name: "",
  });

  const GetAccessoriesListById = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/add-accessories/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const accessoriesList = data.data;
        setAccessoriesList({
          id: accessoriesList.id,
          name: accessoriesList.name,
        });
      });
  };

  useEffect(() => {
    if (search) {
      setAccessoriesList({
        id: rowData.accessoriesid,
        name: rowData.name,
      });
    }
  }, []);

  useEffect(() => {
    if (search) {
      const id = paramIdSeprator(search);
      GetAccessoriesListById(id);
    }
  }, []);

  const onChangeHandler = (e) => {
    let tempObj = { ...accessoriesList };
    tempObj[e.target.id] = e.target.value;
    setAccessoriesList(tempObj);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const id = paramIdSeprator(search);
    let url = search
      ? `${process.env.REACT_APP_API_URL}/update-accessories-drop-down/${id}`
      : `${process.env.REACT_APP_API_URL}/add-accessories`;
    const body = {
      accessoriesid: accessoriesList.id,
      name: accessoriesList.name,
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          history.push("/accessories");
          toast.success(data.message);
          ClearData();
        }
      });
  };

  const ClearData = (e) => {
    setAccessoriesList({
      id: "",
      name: "",
    });
  };

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" forHtml="id">
              Item Id:
            </label>
            <input
              type="text"
              className="form-control"
              id="id"
              value={accessoriesList.id}
              onChange={onChangeHandler}
            />
            <label className="mt-1" forHtml="name">
              Item Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={accessoriesList.name}
              onChange={onChangeHandler}
            />
            <button type="submit" className="btn mt-3 bg_color">
              {search ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddListOfAccessories;
