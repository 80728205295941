import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../components/button/Button";
import * as AuthService from "../services/auth";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  const emailVerification = async (email) => {
    try {
      await AuthService.forgotPasswordByEmailVerification(email).then(() => {
        toast.success("Please check your email for reset the password");
        history.push("/login");
      });
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`${t("emailAddress.invalid")}`)
        .required(`${t("emailAddress.required")}`),
    }),
    onSubmit: (values) => {
      emailVerification(values.email);
    },
  });

  document.getElementById("root").classList = " hold-transition login-page";

  return (
    <div className="login-box">
      <div className="shadow-lg p-3 mb-5 bg-white rounded">
        <div className="user-sidebar mt-3 text-center pb-3 mb-3">
          <img
            src="https://action-cards.com/wp-content/uploads/2022/05/Logo_positiv_CMYK_tagline_h%E2%94%9C%E2%95%95jre_EN_udvidet.svg"
            alt="logo"
            width="80%"
          />
        </div>
        <div className="text-center mb-3">
          <Link to="/forgot-password" className="h1">
            <span>Forgot Password</span>
          </Link>
        </div>
        <div className="card-body ">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control rounded-pill mb-2 mr-2 ml-2"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
                <div className="input-group ">
                  <div className="input-group" />
                </div>
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="col-4 mx-auto mt-3 col-4 rounded-pill ">
              <Button
                block
                style={{ border: "none" }}
                type="submit"
                className="col-12 btn bg_color"
              >
                {t("forgotPassword.button.forgotPassword.label")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
