import React from 'react';
import User from './user-dropdown/UserDropdown';

const Header = ({ toggleMenuSidebar }) => {

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">

      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={() => toggleMenuSidebar()}
            type="button"
            className="nav-link"
            data-widget=""
          >
            <i className="fas fa-bars" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">

        </li>
        <li className="nav-item d-none d-sm-inline-block">

        </li>
      </ul>
      <form className="form-inline ml-3">

      </form>
      <ul className="navbar-nav ml-auto">

        <User userData={JSON.parse(localStorage.getItem('userData'))} />
      </ul>
    </nav>
  );
};

export default Header;
