import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Link, useHistory, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { loginUser } from "../store/reducers/auth";
import * as Yup from "yup";
import Button from "../components/button/Button";
import * as AuthService from "../services/auth";

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();
  const login = async (email, password) => {
    try {
      // setAuthLoading(true);
      await AuthService.loginByAuth(email, password).then((userData) => {
        toast.success("Login is succeed!");
        setAuthLoading(false);
        dispatch(loginUser(userData));
        if (localStorage.getItem("is_admin") === "true") {
          history.push("/manage-organisations");
        } else {
          history.push("/dashboard");
        }
      });
    } catch (error) {
      setAuthLoading(false);
      toast.error("Please enter valid credentials");
    }
  };

  const printFormError = (formik, key) => {
    if (formik.touched[key] && formik.errors[key]) {
      return <div>{formik.errors[key]}</div>;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`${t("emailAddress.invalid")}`)
        .required(`${t("emailAddress.required")}`),
      password: Yup.string()
        .min(1, `${t("emailAddress.min")}`)
        .max(30, `${t("emailAddress.max")}`)
        .required(`${t("emailAddress.password")}`),
    }),
    onSubmit: (values) => {
      login(values.email, values.password);
    },
  });

  document.getElementById("root").classList = "hold-transition login-page";

  return (
    <div className="login-box">
      <div className="shadow-lg p-3 mb-5 bg-white rounded">
        <div className="user-sidebar mt-3 text-center pb-3 mb-3">
          <img
            src="https://action-cards.com/wp-content/uploads/2022/05/Logo_positiv_CMYK_tagline_h%E2%94%9C%E2%95%95jre_EN_udvidet.svg"
            alt="logo"
            width="80%"
            style={{ backgroundColor: "transparent" }}
          />
        </div>
        <div className="text-center mb-3">
          <Link to="/" className="h1">
            <span>Login</span>
          </Link>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control rounded-pill mb-2 mr-2 ml-2"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
                <div className="input-group ">
                  <div className="input-group" />
                </div>
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <div className="input-group ">
                <input
                  type="password"
                  className="form-control rounded-pill mr-2 ml-2"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                />
                <div className="input-group">
                  <div className="input-group" />
                </div>
              </div>
              {printFormError(formik, "password")}
            </div>

            <div className="col-4 mx-auto mt-3 col-4 rounded-pill ">
              <Button
                style={{ border: "none" }}
                block
                type="submit"
                isLoading={isAuthLoading}
                className="col-12 btn bg_color"
              >
                {t("login.button.login.label")}
              </Button>
            </div>

            <div className="text-center mt-3">
              <NavLink className="font-normal" to="/forgot-password">
                Forgot Password
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
