import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../../store/reducers/auth';

const UserDropdown = (props) => {
  const dropdownRef = useRef(null);
  const history = useHistory();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);

  const [dropdownState, updateDropdownState] = useState({
    isDropdownOpen: false
  });

  const logOut = (event) => {
    event.preventDefault();
    dispatch(logoutUser());
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  };

  let className = ' ';

  if (dropdownState.isDropdownOpen) {
    className += ' show';
  }

  return (
    <li ref={dropdownRef} className="">

      <ul className={className}>

        <button
          type="button"
          className="btn float-right bg_color mr-4 fs-8"
          onClick={logOut}
        >
          {t('login.button.Logout')}
        </button>

      </ul>
    </li>
  );
};

export default UserDropdown;
