import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const SingleAction = () => {
  const itemsPerPage = 10;

  const [singledata, setSingledata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const jwtToken = localStorage.getItem("jwt_token");
  const history = useHistory();
  const [filterInput, setFilterInput] = useState("");

  const Single = async (page) => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        setLoading(false);
        if (codeMessage) {
          setSingledata(codeMessage);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
        } else {
          toast.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const data = setTimeout(() => {
      Single(currentPage);
    }, 500);
    return () => {
      clearTimeout(data);
    };
  }, [filterInput]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    Single(event.selected + 1);
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <>
            <div className="row my-2">
              <div className="col">Action-card table</div>
              <div className="col">
                <span className="mr-2">Search and filters:</span>
                <input
                  type="text"
                  placeholder="E.g. Filter for “English Name, AC No"
                  value={filterInput}
                  onChange={(e) => {
                    setFilterInput(e.target.value);
                  }}
                />
              </div>
              <div className="col d-flex justify-content-end">
                <Link to="/single-action/add-single-action">
                  <button
                    className="btn responsive-btn float-right bg_color mr-4 fs-8 m-1"
                    type="text"
                  >
                    Add New Action-card
                  </button>
                </Link>
              </div>
            </div>
          </>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">AC No</th>
                <th scope="col">English Name</th>
                <th scope="col">English Short name</th>
                <th scope="col">Danish Name</th>
                <th scope="col">Danish Short Name</th>
                <th scope="col">Client Type</th>
                <th scope="col">Target country</th>
                <th scope="col">Format</th>
                <th scope="col">Purpose Type</th>
                <th scope="col">Lang.</th>
                <th scope="col">Standard or Tailored</th>
                <th scope="col">Version numbers and AC-pdf file</th>
                <th scope="col">AC docum.</th>
                <th scope="col">Remarks</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {singledata.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">{item.ac_no}</th>
                    <td>{item.english_name}</td>
                    <td>{item.english_short_name}</td>
                    <td>{item.danish_name}</td>
                    <td>{item.danish_short_name}</td>
                    <td>{item.client_type.join(", ")}</td>
                    <td>{item.target_company.join(", ")}</td>
                    <td>{item.format.join(", ")}</td>
                    <td>{item.purpose_type}</td>
                    <td>{item.language.join(", ")}</td>
                    <td>{item.standard_and_tailored}</td>
                    {/* map left */}
                    <td>
                      {item.version_pdf_file.length >= 1
                        ? item.version_pdf_file[
                            item.version_pdf_file.length - 1
                          ].version
                        : null}
                    </td>
                    <td>
                      {item.version_pdf_file.length >= 1
                        ? item.version_pdf_file[
                            item.version_pdf_file.length - 1
                          ].pdf_file
                        : null}
                    </td>
                    <td>{item.remarks ? item.remarks.substring(0, 20) : ""}</td>
                    <td>
                      {/* <i
                    type="button"
                    className="nav-icon fas fa-edit btn btn-lg bg_color"
                    // onClick={() => {
                    //   window.open(
                    //     `/single-action/add-single-action?id=${item.id}`
                    //   );
                    // }}
                    onClick={() => {
                      history.push({
                        pathname: `/single-action/add-single-action`,
                        search: `?id=${item.id}`,
                      });
                    }}
                    ></i> */}
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `/single-action/add-single-action`,
                            search: `?id=${item.id}`,
                          });
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link bg_color"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};
export default SingleAction;
