import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

const AnalysticsDataList = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const [analysticsData, setAnalysticsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([])
  const jwtToken = localStorage.getItem("jwt_token")
  const id = localStorage.getItem("organisation_id")
  const [trainingChecked, setTrainingChecked] = useState(false)
  const [readingChecked, setReadingChecked] = useState(false)

  const checkAllHandler = () => {
    // Check if all checkboxes are already checked
    const allChecked = analysticsData.every((item) =>
      checkedCheckboxes.includes(item.idnb)
    )

    // If all are checked, uncheck all; otherwise, check all
    const updatedCheckboxes = allChecked
      ? [] // Uncheck all
      : analysticsData.map((item) => item.idnb) // Check all

    setCheckedCheckboxes(updatedCheckboxes)
  }

  const onCheckboxHandler = (id) => {
    // Check if the checkbox is already checked
    const isChecked = checkedCheckboxes.includes(id)

    // Create a copy of the checked checkboxes array
    const updatedCheckboxes = [...checkedCheckboxes]

    // If it's checked, remove it from the list
    if (isChecked) {
      console.log("unchecked this id:", id)
      const index = updatedCheckboxes.indexOf(id)
      if (index > -1) {
        updatedCheckboxes.splice(index, 1) // Remove the ID from the array
        setCheckedCheckboxes(updatedCheckboxes)
      }
    } else {
      console.log("checked this id:", id)
      updatedCheckboxes.push(id) // Add the ID to the array
      setCheckedCheckboxes(updatedCheckboxes)
    }
    // All checked id get here :)
    console.log(updatedCheckboxes)
  }

  const handleTrainingCheckboxChange = () => {
    setTrainingChecked(!trainingChecked) // Toggle the checked status
  }

  const handleReadingCheckboxChange = () => {
    setReadingChecked(!readingChecked) // Toggle the checked status
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/ac-analytics/${id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        const data = await response.json()
        if (response.ok) {
          const apiData = data.data
          let filteredData = []
          if (trainingChecked && readingChecked) {
            filteredData = apiData.filter(
              (item) =>
                (item.training_purpose === 0 && item.has_read === 0) ||
                (item.training_purpose === 1 && item.has_read === 1) ||
                (item.training_purpose === 1 && item.has_read === 0)
            )
          } else if (readingChecked) {
            filteredData = apiData.filter(
              (item) =>
                (item.training_purpose === 1 && item.has_read === 1) ||
                (item.training_purpose === 1 && item.has_read === 0)
            )
          } else if (trainingChecked) {
            filteredData = apiData.filter(
              (item) =>
                (item.training_purpose === 0 && item.has_read === 0) ||
                (item.training_purpose === 1 && item.has_read === 0)
            )
          } else {
            filteredData = apiData.filter(
              (item) =>
                (item.training_purpose === 1 && item.has_read === 0)
            )
          }
          setAnalysticsData(filteredData)
        } else {
          toast.error(data.error)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }

    fetchData()
  }, [trainingChecked, readingChecked]) // Only re-run the effect if these values change

  const handelclickData = (id) => {
    const jwtToken = localStorage.getItem("jwt_token")
    const organisation_id = localStorage.getItem("organisation_id")
    let url = `${process.env.REACT_APP_API_URL}/ac-analytics?id=${id}&org_id=${organisation_id}`
    console.log(url)
    fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        const urlLink = data.data
        console.log(urlLink)
        // Create an anchor element
        const link = document.createElement("a")
        link.href = urlLink

        // Set the download attribute and trigger click
        link.setAttribute("download", "filename.ext") // Specify filename and extension
        link.style.display = "none" // Hide the link
        document.body.appendChild(link) // Append the link to the document body
        link.click() // Simulate a click on the link
        document.body.removeChild(link) // Remove the link from the document body after download
      })
  }
  return (
    <>
      <div className='container justify-content-center mt-2'>
        <div className='row text-center mb-4'>
          <div className='col'>
            <h3>{t("userMenuSidebar.label.AnalysticsData")}</h3>
          </div>
        </div>
      </div>
      <hr />
      <div className='col-7'>
        <div className='row my-2'>
          <div className='col-md-auto'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                id='trainingCheckbox'
                checked={trainingChecked}
                onChange={handleTrainingCheckboxChange}
              />
              <label className='form-check-label' htmlFor='trainingCheckbox'>
                <h6>Training</h6>
              </label>
            </div>
          </div>
          <div className='col-md-auto'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                id='readingCheckbox'
                checked={readingChecked}
                onChange={handleReadingCheckboxChange}
              />
              <label className='form-check-label' htmlFor='readingCheckbox'>
                <h6>Reading</h6>
              </label>
            </div>
          </div>
          <div className='col-md-auto'>
            <div className='col'>
              <button
                className='btn responsive-btn float-right bg_color fs-8 mb-2'
                // style={{ marginRight: "150px" }}
                type='button'
                onClick={checkAllHandler}
              >
                Fill All Checkbox
              </button>
            </div>
          </div>
          <div className='col-md-auto'>
            <button
              className='btn responsive-btn float-right bg_color fs-8 mb-2'
              type='button'
              onClick={() => handelclickData(checkedCheckboxes)}
            >
              Download Selected Report Data
            </button>
          </div>
        </div>
      </div>

      <div className='row'>
        {loading ? (
          <div className='text-center' style={{ width: "100%" }}>
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>ID</th>
                  <th scope='col'>First name and Surname of Injured party</th>
                  <th scope='col'>Phone number for Injured party</th>
                  <th scope='col'>Email address for Injured party</th>
                  <th scope='col'>First name and Surname of witness</th>
                  <th scope='col'>Phone number of witness</th>
                  <th scope='col'>Email or witness</th>
                  <th scope='col'>Date</th>
                  <th scope='col'>Point in time</th>
                  <th scope='col'>Set number</th>
                  <th scope='col'>Action card name</th>
                  <th scope='col'>Action card id</th>
                  <th scope='col'>Action card version</th>
                  <th scope='col'>Purpose</th>
                  <th scope='col'>Comment</th>
                  <th scope='col'>Employee name</th>
                  <th scope='col'>Action</th>
                  <th scope='col'>Select Report To Download</th>
                  <th scope='col'>Single Report Download</th>
                </tr>
              </thead>
              <tbody>
                {analysticsData.map((item, i) => {
                  let resultString = ""

                  if (item.training_purpose === 1 && item.has_read === 0) {
                    resultString = "Actual incident"
                  } else if (
                    item.training_purpose === 0 &&
                    item.has_read === 0
                  ) {
                    resultString = "Training"
                  } else if (
                    item.training_purpose === 1 &&
                    item.has_read === 1
                  ) {
                    resultString = "Reading"
                  }
                  return (
                    <tr key={item.idnb}>
                      <th scope='row'>{item.idnb}</th>
                      <td>{item.injured_option_name}</td>
                      <td>{item.injured_option_phone}</td>
                      <td>{item.injured_option_email}</td>
                      <td>{item.witeness_option_name}</td>
                      <td>{item.witeness_option_phone}</td>
                      <td>{item.witeness_option_email}</td>
                      <td>{item.utc_tid_skannet.split(" ")[0]}</td>
                      <td>{item.utc_tid_skannet.split(" ")[1].slice(0, -7)}</td>
                      <td>{item.nb_set}</td>
                      <td>{item.action_card_name}</td>
                      <td>{item.action_card_number}</td>
                      <td>{item.version}</td>
                      <td>{resultString}</td>
                      <td>{item.comments}</td>
                      <td>{item.employee}</td>
                      <td>
                        <i
                          type='button'
                          className='nav-icon fas fa-edit btn btn-lg bg_color'
                          onClick={() => {
                            history.push({
                              pathname: `/analystics/update`,
                              search: `?id=${item.idnb}`,
                              state: { rowData: item },
                            })
                          }}
                        ></i>
                      </td>
                      <td>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`checkbox-${item.idnb}`}
                            checked={checkedCheckboxes.includes(item.idnb)}
                            onChange={() => onCheckboxHandler(item.idnb)}
                          />
                          <label
                            className='form-check-label'
                            htmlFor={`checkbox-${item.idnb}`}
                          >
                            {/* Your label content */}
                          </label>
                        </div>
                      </td>
                      <td>
                        <i
                          type='button'
                          className='nav-icon fas fa-solid fa-download btn-lg bg_color'
                          onClick={() => handelclickData(item.idnb)}
                        ></i>
                      </td>
                    </tr>
                  )
                })}

                <tr hidden={analysticsData.length !== 0}>
                  <td colSpan='5' className='text-center'>
                    Data Not Found
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  )
}
export default AnalysticsDataList
