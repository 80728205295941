import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: !!localStorage.getItem('accessToken'),
  token: localStorage.getItem('accessToken'),
  currentUser: {
    email: 'mail@example.com',
    picture: null,
    name: '',
    id: 0
  }
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      localStorage.setItem('accessToken', payload.access_token);
      state.currentUser = payload.data;
      state.isLoggedIn = true;
      state.token = payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      state.currentUser = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, { payload }) => {
      state.currentUser = payload;
    }
  }
});

export const { loginUser, logoutUser, loadUser } = authSlice.actions;

export default authSlice.reducer;
