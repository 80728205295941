import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Prototype = () => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [proto, setProto] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const jwtToken = localStorage.getItem("jwt_token");
  const [filterInput, setFilterInput] = useState("");
  const history = useHistory();

  const ManageOrgApi = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/prototype?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data.prototype_details;
        setLoading(false);
        if (codeMessage) {
          setProto(codeMessage);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
        }
      });
  };

  const makeACopy = (id) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/copy-prototype/${id}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        history.push({
          pathname: `prototype/add-prototype`,
          search: `?id=${data.data.id}`,
        });
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    ManageOrgApi(event.selected + 1);
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      ManageOrgApi(currentPage);
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [filterInput]);
  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col">Prototype overview table</div>
            <div className="col">
              <span className="mr-2">Search and filters:</span>
              <input
                type="text"
                placeholder="E.g. Filter for “ProtoType No, English SetName"
                value={filterInput}
                onChange={(e) => {
                  setFilterInput(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <Link to="/prototype/add-prototype">
                <button
                  type="button"
                  className="btn responsive-btn float-right bg_color mr-4 fs-8 mt-2"
                >
                  Add New Prototype Set
                </button>
              </Link>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">ProtoType No</th>
                <th scope="col">English SetName:</th>
                <th scope="col">Client Type:</th>
                <th scope="col">Target Country:</th>
                <th scope="col">Format</th>
                <th scope="col">Purpose Type</th>
                <th scope="col">Lang.</th>
                <th scope="col">Standard/tailored</th>
                <th scope="col">Edit</th>
                <th scope="col">Copy</th>
              </tr>
            </thead>
            <tbody>
              {proto.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">{item.prototype_no}</th>
                    <td>{item.english_set_name}</td>
                    <td>{item.client_type}</td>
                    <td>{(item.target_company ?? []).join(", ")}</td>
                    <td>{(item.format ?? []).join(", ")}</td>
                    <td>{item.purpose_type}</td>
                    <td>{(item.language ?? []).join(", ")}</td>

                    <td>{item.standard_and_tailored}</td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `prototype/add-prototype`,
                            search: `?id=${item.id}`,
                          });
                        }}
                      ></i>
                    </td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-copy btn btn-lg bg_color"
                        onClick={() => {
                          makeACopy(item.id);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
              <tr hidden={proto.length !== 0}>
                <td colSpan="5" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link bg_color"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default Prototype;
