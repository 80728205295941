import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useHistory, useLocation } from "react-router-dom"

const UpdateAnalysticsDetails = () => {
  const jwtToken = localStorage.getItem("jwt_token")
  const { search } = useLocation()
  const history = useHistory()

  const paramIdSeprator = (param) => {
    const paramId = param.split("=")
    return paramId[1]
  }

  const location = useLocation()
  const rowData = location.state?.rowData

  const [analyticsListDetails, setAnalyticsListDetails] = useState({
    date: "",
    point_time: "",
    set_number: "",
    action_card: "",
    actioncard_version: "",
    purpose: "",
    comment: "",
    employee_name: "",
    firstname_inquired_party: "",
    phone_number_inquired_party: "",
    email_inquired_party: "",
    firstname_witness: "",
    phone_number_witness: "",
    email_witness: "",
    updated_at: "",
  })

  useEffect(() => {
    setAnalyticsListDetails({
      date: rowData.utc_tid_skannet.split(" ")[0],
      point_time: rowData.utc_tid_skannet.split(" ")[1].slice(0, -7),
      set_number: rowData.nb_set,
      action_card: rowData.action_card_name,
      action_card_number: rowData.action_card_number,
      actioncard_version: rowData.version,
      purpose: rowData.training_purpose,
      comment: rowData.comments,
      employee_name: rowData.employee,
      firstname_inquired_party: rowData.injured_option_name,
      phone_number_inquired_party: rowData.injured_option_phone,
      email_inquired_party: rowData.injured_option_email,
      firstname_witness: rowData.witeness_option_name,
      phone_number_witness: rowData.witeness_option_phone,
      email_witness: rowData.witeness_option_email,
      updated_at: rowData.updated_at,
    })
  }, [])

  const onChangeHandler = (e) => {
    let tempObj = { ...analyticsListDetails }
    tempObj[e.target.id] = e.target.value
    setAnalyticsListDetails(tempObj)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    if (search) {
      const id = paramIdSeprator(search)
      const body = {
        comments: analyticsListDetails.comment,
        employee: analyticsListDetails.employee_name,
        injured_option_name: analyticsListDetails.firstname_inquired_party,
        injured_option_phone: analyticsListDetails.phone_number_inquired_party,
        injured_option_email: analyticsListDetails.email_inquired_party,
        witeness_option_name: analyticsListDetails.firstname_witness,
        witeness_option_phone: analyticsListDetails.phone_number_witness,
        witeness_option_email: analyticsListDetails.email_witness,
      }

      fetch(`${process.env.REACT_APP_API_URL}/ac-analytics/${id}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then(async (response) => {
          const data = await response.json()
          if (response.ok) {
            toast.success(data.message)
            history.push("/administration")
          }
          return Promise.reject(data.error)
        })
        .catch((error) => {
          toast.error(error)
        })
    }
  }
  // Convert the UTC time to local time
  const localTime = new Date(analyticsListDetails.updated_at)
  const localTimeString = localTime.toLocaleString() // Convert to local time string

  return (
    <>
      <div className='row'>
        <div className='col-6'>Analystics Details</div>
      </div>

      <form onSubmit={onSubmitHandler}>
        <div className='row'>
          <div className='col-md-6'>
            <label className='mt-1' htmlFor='date'>
              Date:
            </label>
            <input
              type='date'
              className='form-control'
              id='date'
              value={analyticsListDetails.date}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='firstname_inquired_party'>
              First name and Surname of injured party{" "}
            </label>
            <input
              type='text'
              className='form-control'
              id='firstname_inquired_party'
              value={analyticsListDetails.firstname_inquired_party}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='phone_number_inquired_party'>
              Phone number for injured party
            </label>
            <input
              type='text'
              className='form-control'
              id='phone_number_inquired_party'
              value={analyticsListDetails.phone_number_inquired_party}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='email_inquired_party'>
              Email address for injured party
            </label>
            <input
              type='text'
              className='form-control'
              id='email_inquired_party'
              value={analyticsListDetails.email_inquired_party}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='firstname_witness'>
              First name and Surname of witness{" "}
            </label>
            <input
              type='text'
              className='form-control'
              id='firstname_witness'
              value={analyticsListDetails.firstname_witness}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='phone_number_witness'>
              Phone number of witness
            </label>
            <input
              type='tel'
              className='form-control'
              id='phone_number_witness'
              value={analyticsListDetails.phone_number_witness}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='email_witness'>
              Email or witness
            </label>
            <input
              type='email'
              className='form-control'
              id='email_witness'
              value={analyticsListDetails.email_witness}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='point_time'>
              Point In Time
            </label>
            <input
              type='time'
              className='form-control'
              id='point_time'
              value={analyticsListDetails.point_time}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='set_number'>
              Set Number
            </label>
            <input
              type='number'
              className='form-control'
              id='set_number'
              value={analyticsListDetails.set_number}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='action_card'>
              Action Card Name
            </label>
            <input
              type='text'
              className='form-control'
              id='action_card'
              value={analyticsListDetails.action_card}
              onChange={onChangeHandler}
              disabled
            />
            <label className='mt-1' htmlFor='action_card'>
              Action Card Number
            </label>
            <input
              type='text'
              className='form-control'
              id='action_card'
              value={analyticsListDetails.action_card_number}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='actioncard_version'>
              Action Card Version
            </label>
            <input
              type='text'
              className='form-control'
              id='actioncard_version'
              value={analyticsListDetails.actioncard_version}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='purpose'>
              Purpose
            </label>
            <input
              type='text'
              className='form-control'
              id='purpose'
              value={analyticsListDetails.purpose}
              onChange={onChangeHandler}
              disabled
            />

            <label className='mt-1' htmlFor='comment'>
              Comment
            </label>
            <textarea
              type='text'
              className='textarea medium'
              id='comment'
              value={analyticsListDetails.comment}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='employee_name'>
              Employee Name
            </label>
            <input
              type='text'
              className='form-control'
              id='employee_name'
              value={analyticsListDetails.employee_name}
              onChange={onChangeHandler}
            />
            <label className='mt-1' htmlFor='text'>
              Last Updated Timestamp:
            </label>
            <input
              type='text'
              className='form-control'
              id='text'
              value={localTimeString}
              disabled
            />

            <button type='submit' className='btn m-3 bg_color'>
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default UpdateAnalysticsDetails
