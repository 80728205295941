import { update } from "lodash";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";

const ActionOrganisation = () => {
  const [unit, setUnit] = useState([]);
  const { search } = useLocation();
  const [unitDivision, setUnitDivision] = useState("");
  const [actionCards, setActionCards] = useState("");
  const [editData, setEditData] = useState();
  const [actionCardDelete, setActionCardDelete] = useState("");

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

  const jwtToken = localStorage.getItem("jwt_token");
  // get api for unit
  const data = (id) => {
    let url = `${process.env.REACT_APP_API_URL}/organisations/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.organizations.unit_division;
        const units = []
        for (let code of codeMessage) {
          if (code.actioncard_set_number.includes("-")) {
            const sets = code.actioncard_set_number.split("-")
            for (const cards of range(parseInt(sets[0]), parseInt(sets[1]), 1)) {
              units.push({
                "id": code.id,
                "unit_or_division": code.unit_or_division,
                "actioncard_set_number": parseInt(cards)
              });
            }
          } else {
            units.push({
              "id": code.id,
              "unit_or_division": code.unit_or_division,
              "actioncard_set_number": parseInt(code.actioncard_set_number)
            });
          }
        }
        console.log(units);
        setUnit(units);
      });
  };

  // post api for unit
  const addUnit = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/unit-division/${id}`;
    const body = {
      unit_or_division: unitDivision,
      action_card_sets: actionCards.split(","),
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      return res.json();
    })
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          data(id);
          toast.success("Data Added");
        }
      });
  };
  useEffect(async () => {
    const id = paramIdSeprator(search);
    if (search) {
      data(id);
    }
  }, []);

  // put api
  const Update = (id) => {
    let url = `${process.env.REACT_APP_API_URL}/unit-division-update/${id}`;
    const body = {
      unit_or_division: unitDivision,
      id: select.id,
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      }),
    })
      .then((res) => {
        res.json();
      })
      .then((data) => {
        if (data) {
          toast.error("Something went wrong!!!");
        } else {
          toast.success("Data Updated");
        }
      });
  };

  const submitData = () => {
    if (editData) {
      Update(editData.id);
    } else {
      const id = paramIdSeprator(search);
      addUnit(id);
    }
  };


  const clearForm = () => {
    setEditData();
    setUnitDivision("");
  };

  const deleteActionCard = () => {
    let url = `${process.env.REACT_APP_API_URL}/delete-action-unit-division/${parseInt(actionCardDelete)}`;
    fetch(url, {
      method: "delete",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      }),
    })
      .then((res) => {
        res.json();
      })
      .then((datas) => {
        if (datas) {
          toast.error("Something went wrong!!!");
        } else {
          toast.success("Data Updated");
          const id = paramIdSeprator(search);
          if (search) data(id);
        }
      });
  };
  return (
    <>
      <div className="row my-2">
        <div className="col-6">Action Organisation </div>
        <div className="col-6 d-flex justify-content-end">
          <button
            type="button"
            className="btn bg_color"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@mdo"
            onClick={clearForm}
          >
            Add Units/Divisions
          </button>
        </div>
      </div>
      {/* popup model */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Units/Divisions
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  Units/Divisions:
                </label>
                <input
                  className="form-control"
                  id="message-text"
                  value={unitDivision}
                  onChange={(e) => {
                    setUnitDivision(e.target.value);
                  }}
                />
                <label htmlFor="message-text" className="col-form-label">
                  Actioncard Set Number
                </label>
                <input
                  className="form-control"
                  id="action-card-set"
                  value={actionCards}
                  onChange={(e) => {
                    setActionCards(e.target.value);
                  }}
                />

              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={submitData}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete actioncard sets popup */}
      <div
        className="modal fade"
        id="deleteactioncard"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Are You Sure Want To Delete This Action Card Number{" "}
                {actionCardDelete} ?
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={deleteActionCard}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* table */}
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Units/Divisions</th>
            <th scope="col">Actioncard Set Number</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {unit.map((item, i) => {
            return (
              <tr key={item.id}>
                <th scope="row">{item.id}</th>
                <td>{item.unit_or_division}</td>
                <td>{item.actioncard_set_number}</td>
                <td>
                  <button
                    type="button"
                    className="btn delete-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteactioncard"
                    data-bs-whatever="@mdo"
                    onClick={() =>
                      setActionCardDelete(item.actioncard_set_number)
                    }
                  >
                    <MdOutlineDeleteOutline size={22} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ActionOrganisation;
