import { toast } from "react-toastify";
const axios = require('axios').default;

export const loginByAuth = async (email, password) => {
  const data = {

  }

  var bodyFormData = new FormData();
  bodyFormData.append('username', email);
  bodyFormData.append('password', password)

  const config = {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  };
  try {
    const url = `${process.env.REACT_APP_API_URL}/login`
    return await axios.post(url, bodyFormData, config)
      .then((res) => {
        const user = { ...res.data };
        if (user) {
          localStorage.setItem('jwt_token', user.access_token)
          localStorage.setItem('is_admin', user.user_details.is_admin)
          localStorage.setItem('user_id', user.user_details.id)
          localStorage.setItem('type', user.org_type)
        }
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');
        return data;
      }).catch((err) => {
        throw err
      });
  } catch (err) {
    throw err;
  }
};

export const forgotPasswordByEmailVerification = async (email) => {
  const data = {
    email
  }

  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/forget-password`, data, config)
    toast.success(response.data.message);
    return response.data;
  } catch (err) {
    toast.eror(err.response.data.error);
  }
};

export const resetPassword = async (password, token) => {
  const data = {
    password
  }

  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password/${token}`, data, config)
    toast.success(response.data.message);
    return response.data;
  } catch (err) {
    toast.error(err.response.data.error);
    throw err;

  }
};

