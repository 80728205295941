import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

const ManageOrganisations = () => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [org, setOrg] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const jwtToken = localStorage.getItem("jwt_token");
  const history = useHistory();

  const manageOrgApi = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/organisations?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data;
        if (codeMessage) {
          setOrg(codeMessage.organizations);
          setPageCount(Math.ceil(codeMessage.count / itemsPerPage));

          setLoading(false);
        } else {
          response.statusText == 404;
          // toast.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      });
  };
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    manageOrgApi(event.selected + 1);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      manageOrgApi(currentPage);
    }, 500);
    return () => clearTimeout(getData);
  }, [filterInput]);

  const handleUnitNo = (data, index, showMore) => {
    const isShowMore = showMore | false;
    if (data.length > 3) {
      return (
        <div>
          {isShowMore == false ? (
            <div>
              <h5>{data[0].actioncard_set_number}</h5>
              <h5>{data[1].actioncard_set_number}</h5>
              <h5>{data[2].actioncard_set_number}</h5>
            </div>
          ) : (
            data.map((one) => {
              return <h5>{one.actioncard_set_number}</h5>;
            })
          )}

          <button
            className="btn bg_color responsive-btn"
            onClick={(e) => {
              const temp = [...org];
              temp[index].isShowMore = isShowMore == false ? true : false;
              setOrg(temp);
            }}
          >
            {isShowMore == false ? "Show More" : "Show Less"}
          </button>
        </div>
      );
    } else {
      return data.map((one) => {
        return <h5>{one.actioncard_set_number}</h5>;
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col">Manage organisations</div>
            <div className="col">
              <span className="mr-2">Search and filters:</span>
              <input
                type="text"
                placeholder="E.g. Filter for “Name, Email & Customer ID"
                value={filterInput}
                onChange={(e) => {
                  setFilterInput(e.target.value);
                }}
              />
            </div>
            <div className="col d-flex justify-content-end">
              <Link to="/manage-organisations/add-organisation">
                <button
                  className="btn responsive-btn float-right bg_color mr-4 fs-8 m-1"
                  type="text"
                >
                  Add New Organisation
                </button>
              </Link>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name of organisation</th>
                <th scope="col">E-mail address</th>
                <th scope="col">Action-cards set numbers</th>
                <th scope="col">Form</th>
                <th scope="col">Customer ID</th>
                <th scope="col">Employee list</th>
                <th scope="col" className="text-center">
                  Units/Divisions
                </th>
                <th scope="col">Remarks</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {org &&
                org.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        {handleUnitNo(item.unit_division, i, item.isShowMore)}
                      </td>
                      <td>{item.form}</td>
                      <td>{item.customer_id}</td>
                      <td>{}</td>
                      <td className="text-center">
                        <Link
                          to={`/manage-organisations/actions?id=${item.id}`}
                        >
                          <button className="btn btn-lg bg_color ">
                            <i className="nav-icon fas fa-eye "></i>
                          </button>
                        </Link>
                      </td>
                      <td>{item?.remarks?.substring(0, 20)}</td>
                      <td>
                        <i
                          type="button"
                          className="nav-icon fas fa-edit btn btn-lg bg_color"
                          onClick={() => {
                            history.push({
                              pathname: `/manage-organisations/add-organisation`,
                              search: `?id=${item.id}`,
                            });
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              <tr hidden={org.length !== 0}>
                <td colSpan="5" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={0}
      />
    </>
  );
};
export default ManageOrganisations;
