import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser, logoutUser } from "../store/reducers/auth";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AdminMenuSidebar from "./menu-sidebar/AdminMenuSidebar";
import PageLoading from "../components/page-loading/PageLoading";
import SingleAction from "../admin/SingleAction";
import ManageOrganisations from "../admin/ManageOrganisations";
import PageNotFound from "../admin/PageNotFound";
import AdminPeron from "../admin/AdminPeron";
import Prototype from "../admin/Prototype";
import TailoredAction from "../admin/Inventory";
import Shipment from "../admin/Shipment";
import Production from "../admin/Production";
import PrivatePerson from "../admin/PrivatePerson";
import AddOrganisation from "../admin/AddOrganisation";
import AddPerson from "../admin/AddPerson";
import AddPrivate from "../admin/AddPrivate";
import AddSingleActionCard from "../admin/AddSingleActionCard";
import AddProtoType from "../admin/AddProtoType";
import AddProduction from "../admin/AddProduction";
import ActionOrganisation from "../admin/ActionOrganisation";
import AddShipment from "../admin/AddShipment";
import AdminPermission from "../admin/AdminPermission";
import AdminPermissionList from "../admin/AdminPermissionList";
import UserMenuSidebar from "./menu-sidebar/UserMenuSidebar";
import AcAnalytics from "../users/AcAnalytics";
import InstructionVideo from "../users/InstructionVideo";
import Administration from "../users/Administration";
import AccountDetails from "../users/AccountDetails";
import ChangeOrganization from "../users/ChangeOrganization";
import UpdateAdministrationDetail from "../users/UpdateAdministrationDetail";
import Dashboard from "../users/Dashboard";
import BuyAccessories from "../admin/AddAccessories";
import AccessoriesList from "../admin/AccessoriesList";
import SellAccessories from "../admin/SellAccessories";
import UpdateAccessories from "../admin/UpdateAccessories";
import AccessoriesTransactionList from "../admin/AccessoriesTransactionList"
import ActionCardsForm from "../admin/ActionCardsForm";
import  AnalysticsDataList from "../users/AnalysticsList";
import UpdateAnalysticsDetails from "../users/UpdateAnalystics";
import AddListOfAccessories from "../admin/ListAccessories";

const Main = () => {
  const dispatch = useDispatch();

  const [appLoadingState, updateAppLoading] = useState(false);
  const [menusidebarState, updateMenusidebarState] = useState({
    isMenuSidebarCollapsed: false,
  });

  const toggleMenuSidebar = () => {
    updateMenusidebarState({
      isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed,
    });
  };

  useEffect(() => {
    updateAppLoading(true);
    const fetchProfile = async () => {
      try {
        const data = JSON.parse(localStorage.getItem("userProfile"));
        dispatch(loadUser(data));
        updateAppLoading(false);
      } catch (error) {
        dispatch(logoutUser());
        updateAppLoading(false);
      }
    };
    fetchProfile();
    return () => { };
  }, []);

  document.getElementById("root").classList.remove("register-page");
  document.getElementById("root").classList.remove("login-page");
  document.getElementById("root").classList.remove("forgot-password");
  document.getElementById("root").classList.remove("reset-password/:token");
  document.getElementById("root").classList.remove("hold-transition");

  document.getElementById("root").className += " sidebar-mini";

  if (menusidebarState.isMenuSidebarCollapsed) {
    document.getElementById("root").classList.add("sidebar-collapse");
    document.getElementById("root").classList.remove("sidebar-open");
  } else {
    document.getElementById("root").classList.add("sidebar-open");
    document.getElementById("root").classList.remove("sidebar-collapse");
  }

  let template;
  let { path, url } = useRouteMatch();

  if (appLoadingState) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <Header toggleMenuSidebar={toggleMenuSidebar} />
        {localStorage.getItem("is_admin") === 'true' ? <AdminMenuSidebar /> : <UserMenuSidebar />}
        <div className="content-wrapper ">
          <div className="pt-3" />
          <section className="content">
            <Switch>
              {/* <--- Admin Menu Route ---> */}
              {localStorage.getItem("is_admin") === 'true' ?
                <>
                  <Route exact path="/" component={ManageOrganisations} />
                  <Route exact path="/manage-organisations" component={ManageOrganisations} />
                  <Route exact path="/manage-organisations/actions" component={ActionOrganisation} />
                  <Route exact path="/admin-person" component={AdminPeron} />
                  <Route path="/admin-person/permission-control" component={AdminPermission} />
                  <Route exact path="/private-person" component={PrivatePerson} />
                  <Route exact path="/admin-person/adminpermission-list" component={AdminPermissionList} />
                  <Route exact path="/single-action" component={SingleAction} />
                  <Route exact path="/prototype" component={Prototype} />
                  <Route exact path="/production" component={Production} />
                  <Route exact path="/accessories" component={AccessoriesList} />
                  <Route exact path="/accessories/add-accessories" component={BuyAccessories} />
                  <Route exact path="/accessories/sell-accessories" component={SellAccessories} />
                  <Route exact path="/accessories/update-accessories" component={UpdateAccessories} />
                  <Route exact path="/accessories/transaction-accessories" component={AccessoriesTransactionList} />
                  <Route exact path="/accessories/list-accessories" component={AddListOfAccessories} />
                  <Route exact path="/shipment" component={Shipment} />
                  <Route exact path="/inventory" component={TailoredAction} />
                  <Route exact path="/manage-organisations/add-organisation" component={AddOrganisation} />
                  <Route exact path="/admin-person/add-person" component={AddPerson} />
                  <Route exact path="/private-person/add-private" component={AddPrivate} />
                  <Route exact path="/single-action/add-single-action" component={AddSingleActionCard} />
                  <Route exact path='/prototype/add-prototype' component={AddProtoType} />
                  <Route exact path="/production/add-production" component={AddProduction} />
                  <Route exact path="/shipment/add-shipment" component={AddShipment} />
                  <Route exact path="/form/:id" component={ActionCardsForm} />
                </> : localStorage.getItem("is_admin") === 'false' ?
                  <>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/ac-analytics" component={AcAnalytics} />
                    <Route exact path="/instruction-video" component={InstructionVideo} />
                    <Route exact path="/administration" component={Administration} />
                    <Route exact path="/account-details" component={AccountDetails} />
                    <Route exact path="/change-org" component={ChangeOrganization} />
                    <Route exact path="/administration/update" component={UpdateAdministrationDetail} />
                    <Route exact path="/analystics" component={AnalysticsDataList} />
                    <Route exact path="/analystics/update" component={UpdateAnalysticsDetails} />
                  </> : <Route path="*" component={PageNotFound} />}
              {/* <--- Users Menu Route ---> */}
              <Route path="*" component={PageNotFound} />
            </Switch>
          </section>
        </div>
        <Footer />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={toggleMenuSidebar}
          onKeyDown={() => { }}
        />
      </>
    );
  }

  return <div className="wrapper">{template}</div>;
};

export default Main;
