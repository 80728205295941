import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CgOrganisation } from "react-icons/cg";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { RiAdminFill } from "react-icons/ri";
import { AiFillApi } from "react-icons/ai";
import { FiType } from "react-icons/fi";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { MdLocalShipping } from "react-icons/md";
import { RxCardStackPlus } from "react-icons/rx";
import { MdCollectionsBookmark } from "react-icons/md";

const AdminMenuSidebar = () => {
  const [t] = useTranslation();

  return (
    <aside className="main-sidebar bg-bg_color bg_color elevation-5">
      <div className="sidebar">
        <div className="user-sidebar mt-3 pb-3 mb-3 d-flex">
          <NavLink to="/" className="text-center">
            <div
              style={{
                backgroundColor: "transparent",
                height: "35px",
                paddingTop: "6px",
              }}
            >
              <img src="/img/Logo_negative.svg" alt="logo" width="80%" />
            </div>
          </NavLink>
        </div>
        <nav className="mt-6" style={{ width: "60%" }}>
          <ul
            className="nav nav-pill nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink to="/manage-organisations" exact className="nav-link">
                <CgOrganisation size={22} />
                <p className="ml-2">
                  {t("menusidebar.label.ManageOrganization")}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin-person" exact className="nav-link">
                <RiAdminFill className="bg-color" size={22} />
                <p className="ml-2">{t("menusidebar.label.AdminPerson")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Private-person" exact className="nav-link">
                <BsFillPersonPlusFill size={22} />
                <p className="ml-2">{t("menusidebar.label.ManagePrivate")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/single-action" exact className="nav-link">
                <AiFillApi size={22} />
                <p className="ml-2">{t("menusidebar.label.SingleAction")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/prototype" exact className="nav-link">
                <FiType size={22} />
                <p className="ml-2">{t("menusidebar.label.Prototype")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/production" exact className="nav-link">
                <MdOutlineProductionQuantityLimits size={22} />
                <p className="ml-2">{t("menusidebar.label.Production")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/shipment" exact className="nav-link">
                <MdLocalShipping size={22} />
                <p className="ml-2">{t("menusidebar.label.Shipment")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/inventory" exact className="nav-link">
                <RxCardStackPlus size={22} />
                <p className="ml-2">{t("menusidebar.label.Inventory")}</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/accessories" exact className="nav-link">
                <MdCollectionsBookmark size={22} />
                <p className="ml-2">{t("menusidebar.label.Accessories")}</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default AdminMenuSidebar;
