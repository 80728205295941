import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div style={{ marginTop: "20%", textAlign: "center" }}>
      {" "}
      <h1>404 Page NotFound</h1>
      <button className="btn">
        <Link to="/manage-organisations">Back To Home </Link>
      </button>
    </div>
  );
};

export default PageNotFound;
