import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
const PrivatePerson = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [privates, setPrivates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const jwtToken = localStorage.getItem("jwt_token");
  const [filterInput, setFilterInput] = useState("")

  const page = 1;
  const itemsPerPage = 10;
  const PrivateData = async (page) => {
    let url = `${process.env.REACT_APP_API_URL}/private?page=${page}&limit=${itemsPerPage}&search=${filterInput}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.privates;
        if (codeMessage) {
          setPrivates(codeMessage);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
          setLoading(false);
        } else {
          response.statusText == 404;
          toast.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    const getData = setTimeout(() => {
      PrivateData(currentPage);
    }, 500);
    return () => clearTimeout(getData)
  }, [filterInput]);
  const handlePageClick = (event) => {
    console.log(event);
    setCurrentPage(event.selected + 1);
    PrivateData(event.selected + 1);
  };
  const handelData = (data) => {
    return data.map((one) => {
      return <h5>{one.start === one.end ? one.start : `${one.start}-${one.end}`}</h5>;
    })
  }
  return (
    <>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col">Private Person</div>
            <div className="col">
              <span className="mr-2">Search and filters:</span>
              <input type="text" placeholder="E.g. Filter for “Name, Email & Customer ID" value={filterInput} onChange={(e) => { setFilterInput(e.target.value) }} />
            </div>
            <div className="col d-flex justify-content-end">
              <Link to="/Private-person/add-private">
                <button className="btn bg_color m-2 responsive-btn" type="text">
                  Add Person
                </button>
              </Link>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email address</th>
                <th scope="col">Phone number</th>
                <th scope="col">ActionCards Set Number(s)</th>
                <th scope="col">Customer ID</th>
                <th scope="col">Remarks</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {privates.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone_number}</td>
                    <td>{handelData(item.unit_division)}</td>
                    <td>{item.customer_id}</td>
                    <td>{item.remarks?.substring(0, 20)}</td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `/Private-person/add-private`,
                            search: `?id=${item.id}`,
                          })
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
              <tr hidden={privates.length !== 0}>
                <td colSpan="5" className="text-center">
                  Data Not Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link bg_color"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>

  );
};

export default PrivatePerson;
