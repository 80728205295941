import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { DiGoogleAnalytics } from "react-icons/di";
import CryptoJS from "crypto-js";

const AcAnalytics = () => {
  const [t] = useTranslation();
  const secret = localStorage.getItem("customer_id").toString();
  const [selectedLanguage, setSelectedLanguage] = useState("AC-analytics");

  const getUrl = () => {
    const key = "kYy1t9yZNYqO4hDIXQomwzH00wWZNrSb";
    const iv = "aMgrPQ341Kf143Mkf9";
    const blocksize = 32;
    const padwith = "&";

    try {
      const keyHash = CryptoJS.SHA256(key).toString().substring(0, 32);
      const ivHash = CryptoJS.SHA256(iv).toString().substring(0, 16);

      const paddedSecret =
        secret + padwith.repeat(blocksize - (secret.length % blocksize));

      const cipher = CryptoJS.AES.encrypt(
        paddedSecret,
        CryptoJS.enc.Utf8.parse(keyHash),
        {
          iv: CryptoJS.enc.Utf8.parse(ivHash),
          mode: CryptoJS.mode.CFB,
          padding: CryptoJS.pad.NoPadding,
        }
      ).toString();

      const encryptedString = cipher.replace(/\//g, "-");
      return `https://q2m2-ac-gold.azurewebsites.net/?encrypted_customer_id=${encryptedString}`;
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  return (
    <>
      <div className="container justify-content-center mt-2">
        <div className="text-center">
          <h3>
            <DiGoogleAnalytics className="bg-color" size={38} />
            {t("userMenuSidebar.label.RiskAnalysis")}
          </h3>
        </div>

        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link btn-dark ${selectedLanguage === "AC-analytics" ? "active" : ""
                }`}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="true"
              onClick={() => setSelectedLanguage("AC-analytics")}
            >
              {t("userMenuSidebar.label.RiskAnalysis")}
            </button>
          </li>

          {secret === "17" || secret === "144" ? (
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link btn-dark mx-3 ${selectedLanguage === "HR-management" ? "active" : ""
                  }`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="false"
                onClick={() => setSelectedLanguage("HR-management")}
              >
                {t("userMenuSidebar.label.HR-management")}
              </button>
            </li>
          ) : null}
        </ul>
        <hr />

        <div className="box tab-content" id="pills-tabContent">
          {secret === "17" || secret === "144" ? (
            <div
              className="tab-pane fade"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {secret == "17" && (
                <div className="iframe-container">
                  <iframe
                    id="dnk"
                    src="https://q2m2-dnk-analytics.azurewebsites.net/"
                    width="1200px"
                    height="1200px"
                  ></iframe>
                </div>
              )}
              {secret == "144" && (
                <div className="iframe-container">
                  <iframe
                    id="Gentofte LIFE"
                    src="https://q2m2-gentoftelife-hr.azurewebsites.net/"
                    width="1200px"
                    height="1200px"
                  ></iframe>
                </div>
              )}
            </div>
          ) : null}

          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="iframe-container">
              <iframe
                id="ac-analytics"
                title="Ac- Analystics"
                src={getUrl()}
                width="1200px"
                height="1200px"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AcAnalytics;
