import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./CommonModules/Main";
import Login from "./login/Login";
import EmailVerification from "./resetPassword/emailVerification";
import ForgotPassword from "./resetPassword/forgotPassword";
import ActionCardsForm from "./admin/ActionCardsForm";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import "./App.scss";

const App = () => {
  return (
    <Router>
      <Switch>

        <PublicRoute exact path="/login">
          <Login />
        </PublicRoute>
        <Route exact path="/form/:id">
          <ActionCardsForm />
        </Route>
        <PublicRoute exact path="/forgot-password">
          <EmailVerification />
        </PublicRoute>
        <Route exact path="/reset-password/:token">
          <ForgotPassword />
        </Route>
        <PublicRoute exact path="/callback">
          <h1>Callback</h1>
        </PublicRoute>
        <PrivateRoute path="/">
          <Main />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;
