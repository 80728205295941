import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom"

const Language = [
  { value: "Danish", label: "Danish" },
  { value: "English", label: "English" },
];

const Format = [
  { value: "A6", label: "A6" },
  { value: "A7", label: "A7" },
];

const Country = [
  { value: "Denmark", label: "Denmark" },
  { value: "US", label: "US" },
  { value: "UK", label: "UK" },
  { value: "Canada", label: "Canada" },
  { value: "Australia", label: "Australia" },
  { value: "South Africa", label: "South Africa" },
  { value: "New Zealand", label: "New Zealand" },
];

const AddProduction = () => {
  const history = useHistory()
  const { search } = useLocation();
  const jwtToken = localStorage.getItem("jwt_token");
  const [countrys, setCountrys] = useState(Country[0]);
  const [action, setAction] = useState([]);
  const [select, setSelect] = useState(action[0]);
  const [file, setFile] = useState("");
  const [pdf, setPdf] = useState([]);
  const [fieldDisable, setFieldDisable] = useState(search ? true : false);
  const [langs, setLangs] = useState(Language[0]);
  const [files, setFiles] = useState([]);
  const [displayQrNumber, setDisplayQrNumber] = useState();
  const [formValues, setFormValues] = useState([{ id: 1 }]);
  const [card, setCard] = useState([]);
  const [formats, setFormats] = useState(Format[0]);

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [production, setProduction] = useState({
    batch_no: "",
    qr_no: "",
    prototype_set_id: "",
    year: "",
    count: "",
    remarks: "",
    version: NaN,
    // target_company: Country[0],
    // format: Format[0],
    // language: Language[0],
  });

  const onChangeHandler = (e) => {
    let tempObj = { ...production };
    tempObj[e.target.id] = e.target.value;
    setProduction(tempObj);
  };

  // const onCountryChange = (e) => {
  //   let tempObj = { ...production };
  //   tempObj['target_company'] = e
  //   setProduction(tempObj);
  // }

  // const onFormatChange = (e) => {
  //   let tempObj = { ...production };
  //   tempObj['format'] = e
  //   setProduction(tempObj);
  // }

  // const onLanguageChange = (e) => {
  //   let tempObj = { ...production };
  //   tempObj['language'] = e
  //   setProduction(tempObj);
  // }

  const autofilldata = (data) => {
    setSelect(data)
    if (data?.ac_content_toc?.length > 0 && !search) {
      const array_toc = data?.ac_content_toc?.split(",")
      const append_toc_data = []
      if (data?.ac_content_toc?.length > 0) {
        for (const id of array_toc) {
          const filteredCard = card.find((e) => {
            return e.value === id
          })
          append_toc_data.push(filteredCard)
        }
      }
      setFormValues(append_toc_data)
    }
  }

  // add-production post api
  const AddProd = () => {
    let url = `${process.env.REACT_APP_API_URL}/add-production`;
    const returnLabel = (arr) => {
      return arr.map((one) => {
        return one.label;
      });
    };
    let ValueDaragAndDropOrder = []
    ValueDaragAndDropOrder = dragAndDrop?.updatedOrder.map((item) => item.value)
    const bodyFormData = new FormData();
    bodyFormData.append("batch_no", production.batch_no);
    bodyFormData.append("qr_no", displayQrNumber);
    bodyFormData.append("prototype_set_id", select.prototype_no);
    bodyFormData.append("year", production.year);
    bodyFormData.append("count", production.count);
    bodyFormData.append("remarks", production.remarks);
    bodyFormData.append("target_company", Array.isArray(countrys) ? JSON.stringify(returnLabel(countrys)) : JSON.stringify([countrys.label]));
    bodyFormData.append("format", Array.isArray(formats) ? JSON.stringify(returnLabel(formats)) : JSON.stringify([formats.label]));
    bodyFormData.append(
      "language",
      Array.isArray(langs)
        ? JSON.stringify(returnLabel(langs))
        : JSON.stringify([langs.label])
    )
    bodyFormData.append("ac_content_toc", ValueDaragAndDropOrder.length !== 0 ? dragAndDrop?.updatedOrder.map((item) => item.value) : formValues.map((e) => e.value));
    files.length &&
      Array.from(files).forEach((file) => {
        bodyFormData.append("ac_pdf", file);
      });
    bodyFormData.append("version", production.version);
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: bodyFormData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Data Added");
          history.push({
            pathname: `/production`,
          })
        }
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      // update api call here
      const id = paramIdSeprator(search);
      let url = `${process.env.REACT_APP_API_URL}/update-production/${id}`;
      const bodyFormData = new FormData();
      const returnLabel = (arr) => {
        return arr.map((one) => {
          return one.label;
        });
      };
      let ValueDaragAndDropOrder = []
      ValueDaragAndDropOrder = dragAndDrop?.updatedOrder.map((item) => item.value)
      bodyFormData.append("batch_no", production.batch_no);
      bodyFormData.append("qr_no", production.qr_no);
      bodyFormData.append("prototype_set_id", select[0].prototype_no);
      bodyFormData.append("count", production.count);
      bodyFormData.append("year", production.year);
      bodyFormData.append("remarks", production.remarks);
      bodyFormData.append("version", production.version);
      bodyFormData.append("ac_content_toc", ValueDaragAndDropOrder.length !== 0 ? dragAndDrop?.updatedOrder.map((item) => item.value) : formValues.map((e) => e.value));
      bodyFormData.append("target_company", Array.isArray(countrys) ? JSON.stringify(returnLabel(countrys)) : JSON.stringify([countrys.label]));
      bodyFormData.append(
        "format",
        Array.isArray(formats)
          ? JSON.stringify(returnLabel(formats))
          : JSON.stringify([formats.label])
      )
      bodyFormData.append(
        "language",
        Array.isArray(langs)
          ? JSON.stringify(returnLabel(langs))
          : JSON.stringify([langs.label])
      )
      if (file) {
        files.length &&
          Array.from(files).forEach((file) => {
            bodyFormData.append("ac_pdf", file);
          });
      }
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: bodyFormData,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.error) {
            toast.error(data.error);
          } else {
            toast.success("Data Updated")
            setFile("")
            history.push({
              pathname: `/production`,
            })
            document.getElementById("formFile").value = ""
          }
        });
    } else {
      AddProd();
    }
  };

  const ProductionData = async () => {
    let url = `${process.env.REACT_APP_API_URL}/prototype`;
    const res = await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.prototype_details;
        if (codeMessage) {
          codeMessage.map((one) => {
            (one.value = one.prototype_no), (one.label = one.prototype_no);
          });
          setAction(codeMessage);
          return codeMessage;
        }
      });
    return res;
  };

  const Actioncards = async () => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard`;
    return await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        const optionsList = []
        for (const value of codeMessage) {
          optionsList.push({ value: value.ac_no, label: `${value.ac_no} ${value.english_name}` })
        }
        setCard(optionsList);
        return optionsList;
      });
  };

  //get if search
  const GetDataProd = async (id, dropDownOptions, cardData) => {
    let url = `${process.env.REACT_APP_API_URL}/production/${id}`;
    const res = await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.production;
        const filteredCountry = codeMessage.target_company.flatMap((label) => {
          return Country.filter((one) => one.label === label)
        })
        const filteredFormate = codeMessage.format.flatMap((label) => {
          return Format.filter((one) => one.label === label)
        })
        const filteredLang = codeMessage.language.flatMap((label) => {
          return Language.filter((one) => one.label === label)
        })
        setLangs(filteredLang)
        setCountrys(filteredCountry)
        setFormats(filteredFormate)
        setPdf(codeMessage.version_pdf_file);
        setProduction({
          batch_no: codeMessage.batch_no,
          qr_no: codeMessage.qr_no,
          prototype_set_id: codeMessage.prototype_set_id,
          year: codeMessage.year,
          count: codeMessage.count,
          remarks: codeMessage.remarks,
          version: codeMessage.version,
        });
        const selectedPerson = dropDownOptions.filter((one) => {
          return one.prototype_no === codeMessage.prototype_set_id;
        });

        const array_toc = codeMessage?.ac_content_toc?.split(",");
        const smg = [];
        for (const id of array_toc) {
          const filteredCard = cardData.find((e) => {
            return e.value === id
          })
          smg.push(filteredCard)
        }

        setFormValues(smg);

        setSelect(selectedPerson);
      });
    return res;
  };
  //
  useEffect(async () => {
    const actionCards = await Actioncards();
    await QrNumberInAddData();
    const dropDownOptions = await ProductionData();
    const id = paramIdSeprator(search);
    if (id) {
      GetDataProd(id, dropDownOptions, actionCards);
    }
  }, []);

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    const tempList = [...files, ...fileList];
    setFiles(tempList);
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const QrNumberInAddData = async () => {
    let url = `${process.env.REACT_APP_API_URL}/qr-number`;
    await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data;
        setDisplayQrNumber(codeMessage);
      });
  };

  // <------------------------ Start Drag & Drop ------------------------------->
  const [list, setList] = useState(formValues);

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i] = e;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { id: 1 }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    } else {
      setFormValues([{ id: 0 }]);
    }
  };

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  }

  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list
    });

    event.dataTransfer.setData("text/html", '');
  }

  useEffect(() => {
    setList(formValues)
  }, [formValues])

  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }

  }

  const onDrop = (event) => {
    setList(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    });
  }

  // <------------------------ Stop Drag & Drop ------------------------------->

  return (
    <>
      <h3>{paramIdSeprator(search) ? "Update" : "Add"} Production</h3>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          {/* Left Side */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="batch_no">
              Batch No:
            </label>
            <input
              type="number"
              className="form-control"
              id="batch_no"
              value={production.batch_no}
              onChange={onChangeHandler}
              disabled={fieldDisable}
            />
            <label className="mt-1" htmlFor="qr_no">
              QR-gen. no.:
            </label>
            {paramIdSeprator(search) ? (
              <input
                type="text"
                className="form-control"
                id="qr_no"
                value={production.qr_no}
                onChange={onChangeHandler}
                disabled={fieldDisable}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                id="qr_no"
                value={production.qr_no || displayQrNumber}
                onChange={onChangeHandler}
                disabled={fieldDisable}
              />
            )}
            <label className="mt-1" htmlFor="inputEmail4">
              Proto.SetID:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={action ? select : []}
              name="color"
              options={action ? action : []}
              onChange={autofilldata}
              isDisabled={fieldDisable}
            />
            <label className="mt-1" htmlFor="count">
              Count of sets:
            </label>
            <input
              type="number"
              className="form-control"
              id="count"
              value={production.count}
              onChange={onChangeHandler}
              disabled={fieldDisable}
            />
            <label className="mt-1" htmlFor="target_country">
              Target Country:
            </label>
            <Select
              id='target_country'
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Country ? countrys : []}
              name="color"
              options={Country}
              onChange={setCountrys}
            />
            <label className="mt-1" htmlFor="format">
              Format:
            </label>
            <Select
              id='format'
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Format ? formats : []}
              name="color"
              options={Format}
              onChange={setFormats}
            />
            <label className="mt-1" htmlFor="language">
              Language:
            </label>
            <Select
              id='language'
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Language ? langs : []}
              name="color"
              options={Language}
              onChange={setLangs}
            />
          </div>

          {/* Right Side */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="version">
              version:
            </label>
            <input
              type="number"
              className="form-control"
              id="version"
              value={production.version}
              onChange={onChangeHandler}
              disabled={fieldDisable}
            />
            <label className="mt-1" htmlFor="year">
              Year:
            </label>
            <input
              type="number"
              className="form-control"
              id="year"
              value={production.year}
              onChange={onChangeHandler}
              disabled={fieldDisable}
            />
            {paramIdSeprator(search)
              ? pdf.map((e) => (
                <label>
                  version :{e.version}, pdf:{e.ac_pdf}
                </label>
              ))
              : null}
            <br></br>
            <label className="mt-1" htmlFor="formFile">
              AC-document:
            </label>
            <div>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="form-control"
                disabled={fieldDisable}
              />
              {files.length ? (
                <div className="border mt-3 d-flex flex-wrap">
                  {files.map((file, index) => (
                    <div className="ml-3 mr-5 my-1">
                      <div
                        className="border p-1 bg-muted rounded"
                        style={{ backgroundColor: "#e6e6e6" }}
                      >
                        {file.name}{" "}
                        <button
                          type="button"
                          className="btn-sm p-0 px-1 m-1 border-0"
                          style={{ backgroundColor: "#e6e6e6" }}
                          onClick={() => handleRemove(index)}
                        >
                          <b>X</b>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <label className="mt-1" htmlFor="remarks">
              Remarks:
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              value={production.remarks}
              onChange={onChangeHandler}
              disabled={fieldDisable}
            />
            <button
              type="Submit"
              className="btn m-3 bg_color"
              disabled={fieldDisable}
            >
              Print
            </button>
            <button
              className="btn m-3 bg_color"
              disabled={!fieldDisable}
              onClick={(e) => {
                setFieldDisable(false);
              }}
            >
              Unlock
            </button>
          </div>
        </div>
      </form >

      {/* <------------------------ Start Drag & Drop -------------------------------> */}
      <div className="mt-3">
        <section>
          <ul>
            {list.map((element, index) => {
              return (
                <li
                  key={index}
                  data-position={index}
                  draggable
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  className={dragAndDrop && dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}
                >
                  <div style={{ width: "50%" }}>
                    <div class="container">
                      <div>
                        <div key={index}>
                          <div className="row">
                            <div className="col-md-11 mb-3">
                              <Select
                                value={element}
                                onChange={(e) => handleChange(index, e)}
                                options={card}
                              >
                              </Select>
                            </div>
                            <div className="col-md-1">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ height: "36px" }}
                                onClick={() => removeFormFields(index)}
                              >
                                -
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addFormFields}
          >
            +
          </button>
        </section>
      </div>
      {/* // <------------------------ Stop Drag & Drop -------------------------------> */}
    </>
  );
};

export default AddProduction;
