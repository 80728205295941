import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { logoutUser } from "../store/reducers/auth"

const AccountDetails = () => {
  const jwtToken = localStorage.getItem("jwt_token")
  const history = useHistory()
  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    type_of_account: "",
    phone_number: "",
    password: "",
    type: "",
  })
  const [formFamilyValues, setFormFamilyValues] = useState([
    { id: 1, email: "" },
  ])

  useEffect(() => {
    getAccountDetail()
  }, [])

  const onChangeHandler = (e) => {
    setUserDetail({ ...userDetail, [e.target.id]: e.target.value })
  }

  const getAccountDetail = async () => {
    const id = localStorage.getItem("user_id")
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/people/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      const data = await response.json()
      if (!response.ok) throw new Error(data.error)

      const accountDetails = data.data.people_details
      if (localStorage.getItem("type") === "Private") {
        const familyEmails =
          accountDetails?.organisation_and_role[0]?.family_members_email || "[]"
        const formattedFamilyEmails = familyEmails.map((email, index) => ({
          id: index + 1,
          email,
        }))
        setFormFamilyValues(formattedFamilyEmails)
      }

      setUserDetail({
        first_name: accountDetails.first_name,
        last_name: accountDetails.last_name,
        email: accountDetails.email,
        type: accountDetails.type,
        type_of_account: accountDetails.account_type,
        phone_number: accountDetails.phone_number,
      })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault()
    const id = localStorage.getItem("user_id")
    const body = {
      first_name: userDetail.first_name,
      last_name: userDetail.last_name,
      email: userDetail.email,
      phone_number: userDetail.phone_number,
      password: userDetail.password,
      account_type: userDetail.type_of_account,
    }

    if (localStorage.getItem("type") === "Private") {
      const emailFamilyArray = formFamilyValues.map((element) => element.email)
      body.family_members_email = emailFamilyArray.includes(undefined)
        ? null
        : emailFamilyArray
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/people/${id}`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      const data = await response.json()
      if (!response.ok) throw new Error(data.error)

      toast.success(data.message)
      // Call to BulkData function (if exists)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const addFormFamilyFields = () => {
    // console.log()
    const newId = formFamilyValues.length
      ? formFamilyValues[formFamilyValues.length - 1].id + 1
      : formFamilyValues[1]
    setFormFamilyValues([...formFamilyValues, { id: newId, email: "" }])
  }

  const removeFormFamilyFields = (index) => {
    const newFormValues = [...formFamilyValues]
    newFormValues.splice(index, 1)
    setFormFamilyValues(
      newFormValues.length > 0 ? newFormValues : [{ id: 1, email: "" }]
    )
  }

  const onChangeHandler1 = (e, id) => {
    const formValuesCopy = [...formFamilyValues]
    const index = formValuesCopy.findIndex((element) => element.id === id)
    if (index !== -1) {
      formValuesCopy[index].email = e.target.value
      setFormFamilyValues(formValuesCopy)
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-6'>Account Details</div>
      </div>

      <form onSubmit={onSubmitHandler}>
        <div className='row'>
          <div className='col-md-6'>
            <label className='mt-1' htmlFor='first_name'>
              First name:
            </label>
            <input
              type='text'
              className='form-control'
              id='first_name'
              value={userDetail.first_name}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='last_name'>
              Last name:
            </label>
            <input
              type='text'
              className='form-control'
              id='last_name'
              value={userDetail.last_name}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='email'>
              Email address:
            </label>
            <input
              type='email'
              className='form-control'
              id='email'
              value={userDetail.email}
              disabled
              onChange={onChangeHandler}
            />
            {localStorage.getItem("type") == "Private" ? (
              <>
                <label className='mt-1' htmlFor='other_email'>
                  Family Member Email address:
                </label>
                {formFamilyValues.map((element, index) => (
                  <div key={index}>
                    <div className='row'>
                      <div className='col-10 col-md-11 mb-3'>
                        <input
                          type='email'
                          key={index}
                          className='form-control'
                          id={`email_family${element.id}`}
                          value={element.email}
                          onChange={(e) => onChangeHandler1(e, element.id)}
                        />
                      </div>
                      <div className='col-1 col-md-1'>
                        <button
                          type='button'
                          className='btn responsive-btn btn-danger'
                          style={{ height: "36px" }}
                          onClick={() => removeFormFamilyFields(index)}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className='row'>
                  <div className='col-md-1 mb-3'>
                    <button
                      type='button'
                      className='btn responsive-btn btn-primary'
                      onClick={addFormFamilyFields}
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <label className='mt-1' htmlFor='type_of_account'>
              Account Type:
            </label>
            <input
              type='text'
              className='form-control'
              id='type_of_account'
              disabled
              value={userDetail.type}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='phone_number'>
              Phone number:
            </label>
            <input
              type='number'
              className='form-control'
              id='phone_number'
              value={userDetail.phone_number}
              onChange={onChangeHandler}
            />

            <label className='mt-1' htmlFor='password'>
              Change Password:
            </label>
            <input
              type='password'
              className='form-control'
              id='password'
              value={userDetail.password}
              onChange={onChangeHandler}
            />

            <button type='submit' className='btn m-3 bg_color'>
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
export default AccountDetails
