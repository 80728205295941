import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const ClientType = [
  { value: "Business", label: "Business" },
  { value: "Sport", label: "Sport" },
  { value: "Private", label: "Private" },
];

const Purpose = [
  { value: "First Aid", label: "First Aid" },
  { value: "Emergency Service", label: "Emergency Service" },
  { value: "Cyber", label: "Cyber" },
  { value: "Machines", label: "Machines" },
];

const Lang = [
  { value: "Danish", label: "Danish" },
  { value: "English", label: "English" },
];

const Format = [
  { value: "A6", label: "A6" },
  { value: "A7", label: "A7" },
  { value: "Tailored", label: "Tailored" },
];

const Standard = [
  { value: "Standard", label: "Standard" },
  { value: "Tailored", label: "Tailored" },
];

const Country = [
  { value: "Denmark", label: "Denmark" },
  { value: "US", label: "US" },
  { value: "UK", label: "UK" },
  { value: "Canada", label: "Canada" },
  { value: "Australia", label: "Australia" },
  { value: "South Africa", label: "South Africa" },
  { value: "New Zealand", label: "New Zealand" },
];

const AddProtoType = () => {
  const { search } = useLocation();
  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };
  const [countrys, setCountrys] = useState(Country[0]);
  const [clientTypes, setClientTypes] = useState(ClientType[0]);
  const [standards, setStandards] = useState(Standard[0]);
  const [formats, setFormats] = useState(Format[0]);
  const [purposes, setPurposes] = useState(Purpose[0]);
  const [langs, setLangs] = useState(Lang[0]);
  const [remark, setRemark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [eng, setEng] = useState(null);
  const [optionsEngName, setOptionsEngName] = useState(null);
  const [danish_name, setDanish_name] = useState(null);
  const [dname, setDname] = useState(null);
  const [card, setCard] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [formValues, setFormValues] = useState([{ id: 1 }]);
  const [versionNumber, setVersionNumber] = useState(0);
  const [prototype_no, setPrototype_no] = useState("");
  const [files, setFiles] = useState([]);

  // get api
  const jwtToken = localStorage.getItem("jwt_token");
  const Single = async () => {
    let url = `${process.env.REACT_APP_API_URL}/prototype`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.prototype_details;
        if (codeMessage) {
          const initialData = {
            english_set_name: [],
            danish_set_name: [],
            ac_content_toc: [],
            client_type: [],
            target_company: [],
            format: [],
            purpose_type: [],
            language: [],
            standard_and_tailored: [],
            remarks: [],
            ac_pdf_file: [],
          };
          codeMessage.map((one) => {
            one.english_set_name &&
              initialData.english_set_name.push({
                label: one.english_set_name,
                value: one.english_set_name,
              });
            one.danish_set_name &&
              initialData.danish_set_name.push({
                label: one.danish_set_name,
                value: one.danish_set_name,
              });
          });
          setOptionsEngName(initialData.english_set_name);
          setDname(initialData.danish_set_name);
        }
      });
  };
  //
  const createOption = (label) => ({
    label,
  });

  const EngName = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptionsEngName((prev) => [...prev, newOption]);
      setEng(newOption);
    }, 1000);
  };

  const DanishName = (inputValue) => {
    setIsLoading1(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading1(false);
      setDname((prev) => [...prev, newOption]);
      setDanish_name(newOption);
    }, 1000);
  };

  useEffect(async () => {
    Single();
    const data = await Actioncards();
    const id = paramIdSeprator(search);
    if (id) {
      getPrototypeById(id, data);
    }
  }, []);

  // postApi for unit
  const addProtoTypeApi = async () => {
    let url = `${process.env.REACT_APP_API_URL}/add-prototype`;
    const bodyFormData = new FormData();
    const returnLabel = (arr) => {
      return arr.map((one) => {
        return one.label;
      });
    };
    bodyFormData.append("english_set_name", eng?.label);
    bodyFormData.append("danish_set_name", danish_name?.label);
    bodyFormData.append("client_type", clientTypes.label);
    bodyFormData.append(
      "target_company",
      Array.isArray(countrys)
        ? JSON.stringify(returnLabel(countrys))
        : JSON.stringify([countrys.label])
    );
    bodyFormData.append(
      "format",
      Array.isArray(formats)
        ? JSON.stringify(returnLabel(formats))
        : JSON.stringify([formats.label])
    );
    bodyFormData.append(
      "language",
      Array.isArray(langs)
        ? JSON.stringify(returnLabel(langs))
        : JSON.stringify([langs.label])
    );
    bodyFormData.append("purpose_type", purposes?.label);
    bodyFormData.append("standard_and_tailored", standards?.label);
    bodyFormData.append(
      "ac_content_toc", formValues.map((e) => e.value)
    );
    bodyFormData.append("prototype_no", prototype_no);
    bodyFormData.append("remarks", remark);
    bodyFormData.append("version", versionNumber);
    files.length &&
      Array.from(files).forEach((file) => {
        bodyFormData.append("ac_pdf_file", file);
      });
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: bodyFormData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          toast.success("Data Added");
        }
      });
  };

  const getPrototypeById = async (id, cardData) => {
    let url = `${process.env.REACT_APP_API_URL}/get-prototype/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        const array_toc = codeMessage.ac_content_toc.split(",");

        const smg = [];
        for (const id of array_toc) {
          const filteredCard = cardData.find((e) => {
            return e.value === id;
          });
          smg.push(filteredCard);
        }

        setFormValues(smg);
        setEng({
          label: codeMessage.english_set_name,
          value: codeMessage.english_set_name,
        });

        setDanish_name({
          label: codeMessage.danish_set_name,
          value: codeMessage.danish_set_name,
        });

        setRemark(codeMessage.remarks);
        setStandards({
          label: codeMessage.standard_and_tailored,
          value: codeMessage.standard_and_tailored,
        });

        const filteredClientData = ClientType.filter((one) => {
          return codeMessage.client_type.includes(one.label);
        });
        const filteredCountry = Country.filter((one) => {
          return codeMessage.target_company.includes(one.label);
        });
        const filteredFormate = Format.filter((one) => {
          return codeMessage.format.includes(one.label);
        });
        const filteredLen = Lang.filter((one) => {
          return codeMessage.language.includes(one.label);
        });
        // client_type
        setClientTypes(filteredClientData);
        // target_company
        setCountrys(filteredCountry);
        // formats
        setFormats(filteredFormate);
        // language
        setLangs(filteredLen);
        setPrototype_no(codeMessage.prototype_no);
      });
  };
  // update data
  const updateProtoTypeApi = async () => {
    const id = paramIdSeprator(search);
    let url = `${process.env.REACT_APP_API_URL}/prototype/${id}`;
    const bodyFormData = new FormData();
    const returnLabel = (arr) => {
      return arr.map((one) => {
        return one.label;
      });
    };
    bodyFormData.append("english_set_name", eng.label);
    bodyFormData.append("danish_set_name", danish_name.label);
    bodyFormData.append("client_type", clientTypes.label);
    bodyFormData.append(
      "target_company",
      JSON.stringify(returnLabel(countrys))
    );
    bodyFormData.append("language", JSON.stringify(returnLabel(langs)));
    bodyFormData.append("format", JSON.stringify(returnLabel(formats)));
    bodyFormData.append("purpose_type", purposes.label);
    bodyFormData.append("standard_and_tailored", standards.label);
    bodyFormData.append("version", versionNumber);
    bodyFormData.append("prototype_no", prototype_no);
    bodyFormData.append(
      "ac_content_toc", formValues.map((e) => e.value)
    );
    bodyFormData.append("remarks", remark);
    files.length &&
      Array.from(files).forEach((file) => {
        bodyFormData.append("ac_pdf_file", file);
      });
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: bodyFormData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error !== null) {
          toast.error(data.error);
        } else {
          toast.success("Data Added");
          // ClearData();
          setFiles("");
          document.getElementById("formFile").value = "";
        }
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (search) {
      updateProtoTypeApi();
    } else {
      addProtoTypeApi();
    }
  };



  const Actioncards = async () => {
    let url = `${process.env.REACT_APP_API_URL}/actioncard`;
    return await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.actioncard_details;
        const optionsList = [];
        for (const value of codeMessage) {
          optionsList.push({
            value: value.ac_no,
            label: `${value.ac_no} ${value.english_name}`,
          });
        }
        setCard(optionsList);
        return optionsList;
      });
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    const tempList = [...files, ...fileList];
    setFiles(tempList);
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i] = e;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { id: 1 }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    } else {
      setFormValues([{ id: 0 }]);
    }
  };

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  }

  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: formValues
    });

    event.dataTransfer.setData("text/html", '');
  }

  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }

  }

  const onDrop = (event) => {
    setFormValues(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    });
  }
  // console.log(onDrop);

  // <------------------------ Stop Drag & Drop ------------------------------->
  return (
    <>
      Add ProtoType
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="inputEmail4">
              English SetName:
            </label>
            <CreatableSelect
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              onChange={(newValue) => setEng(newValue)}
              onCreateOption={EngName}
              options={optionsEngName}
              value={eng}
            />

            <label className="mt-1" htmlFor="inputEmail4">
              Danish SetName:
            </label>
            <CreatableSelect
              isClearable
              isDisabled={isLoading1}
              isLoading={isLoading1}
              onChange={(newValue) => setDanish_name(newValue)}
              onCreateOption={DanishName}
              options={dname}
              value={danish_name}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Client Type:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={ClientType ? clientTypes : []}
              name="color"
              options={ClientType}
              onChange={setClientTypes}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Target Country:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Country ? countrys : []}
              name="color"
              options={Country}
              onChange={setCountrys}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Format:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Format ? formats : []}
              name="color"
              options={Format}
              onChange={setFormats}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Purpose Type:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={Purpose ? purposes : []}
              name="color"
              options={Purpose}
              onChange={setPurposes}
            />
            <label className="mt-1" htmlFor="inputEmail4">
              Language:
            </label>
            <Select
              className="basic-multi-select"
              isMulti
              classNamePrefix="select"
              value={Lang ? langs : []}
              name="color"
              options={Lang}
              onChange={setLangs}
            />
          </div>
          {/* Right  */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="inputEmail4">
              Standard/Tailored:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={Standard ? standards : []}
              name="color"
              options={Standard}
              onChange={setStandards}
            />
            <label className="mt-1" htmlFor="remarks">
              Remarks:
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              value={remark}
            />

            <label className="mt-1" htmlFor="prototype_no">
              {/* Version no. and AC-pdf file: */}
              ProtoSet Id
            </label>
            <input
              type="text"
              className="form-control"
              id="prototype_no"
              onChange={(e) => {
                setPrototype_no(e.target.value);
              }}
              value={prototype_no}
            />
            <label className="mt-1" htmlFor="version_number">
              {/* Version no. and AC-pdf file: */}
              Version no
            </label>
            <input
              type="number"
              className="form-control"
              id="version_number"
              onChange={(e) => {
                setVersionNumber(e.target.value);
              }}
              value={versionNumber}
            />
            {paramIdSeprator(search)
              ? pdf.map((e) => (
                <label>
                  version :{e.version}, pdf:{e.pdf_file}
                </label>
              ))
              : null}
            <label className="mt-1" htmlFor="formFile">
              AC-document:
            </label>
            <div>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="form-control"
              />
              {files.length ? (
                <div className="border mt-3 d-flex flex-wrap">
                  {files.map((file, index) => (
                    <div className="ml-3 mr-5 my-1">
                      <div
                        className="border p-1 bg-muted rounded"
                        style={{ backgroundColor: "#e6e6e6" }}
                      >
                        {file.name}{" "}
                        <button
                          type="button"
                          className="btn-sm p-0 px-1 m-1 border-0"
                          style={{ backgroundColor: "#e6e6e6" }}
                          onClick={() => handleRemove(index)}
                        >
                          <b>X</b>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <button type="Submit" className="btn m-3 bg_color">
              {paramIdSeprator(search) ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
      {/*<label className="mt-1" htmlFor="Country">AC content/TOC:</label> */}
      {/* <------------------------ Start Drag & Drop -------------------------------> */}
      <div className="mt-3">
        <section>
          <ul>

            {formValues.map((element, index) => {
              return (
                <li
                  key={index}
                  data-position={index}
                  draggable
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  className={dragAndDrop && dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}
                >
                  <div style={{ width: "50%" }}>
                    <div class="container">
                      <div>
                        <div key={index}>
                          <div className="row">
                            <div className="col-md-11 mb-3">
                              <Select
                                value={element}
                                onChange={(e) => handleChange(index, e)}
                                options={card}
                              >
                              </Select>
                            </div>
                            <div className="col-md-1">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ height: "36px" }}
                                onClick={() => removeFormFields(index)}
                              >
                                -
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addFormFields}
          >
            +
          </button>
        </section>
      </div>
      {/* // <------------------------ Stop Drag & Drop -------------------------------> */}
    </>
  );
};

export default AddProtoType;
