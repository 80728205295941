import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const AdminPeron = () => {
  const page = 1;
  const itemsPerPage = 10;
  const [admin, setAdmin] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const jwtToken = localStorage.getItem("jwt_token");
  const history = useHistory();
  const DataPerson = async (page) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/people?page=${page}&limit=${itemsPerPage}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json(page);
      })
      .then((data) => {
        const codeMessage = data.data.people_details;
        if (codeMessage) {
          setAdmin(codeMessage);
          setPageCount(Math.ceil(data.data.count / itemsPerPage));
          setLoading(false);
        } else {
          elif(response.statusText == 404);
          toast.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    DataPerson(currentPage);
  }, []);
  const handlePageClick = (event) => {
    // console.log(event);
    setCurrentPage(event.selected + 1);
    DataPerson(event.selected + 1);
  };
  // console.log(pageCount);
  return (
    <>
      <div className="row my-2">
        <div className="col-6">Admin Person</div>
        <div className="col-6 d-flex justify-content-end">
          <Link to="/admin-person/add-person">
            <button className="btn bg_color m-2" type="text">
              Add person
            </button>
          </Link>
        </div>
      </div>
      {loading ? (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Phone number</th>
                <th scope="col">Organization(s) and role(s)</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {admin.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <th scope="row">
                      {i + 1 + itemsPerPage * (currentPage - 1)}
                    </th>
                    <td>{item.first_name}</td>
                    <td>{item.last_name}</td>
                    <td>{item.phone_number}</td>
                    <td style={{"marginLeft":"12px !impoartent"}}>
                      <i
                        type="button"
                        className="nav-icon fas fa-eye btn-lg bg_color "
                        onClick={() => {
                          history.push({
                            pathname: `/admin-person/adminpermission-list`,
                            search: `?id=${item.id}`,
                          });
                        }}
                      ></i>
                    </td>
                    <td>
                      <i
                        type="button"
                        className="nav-icon fas fa-edit btn btn-lg bg_color"
                        onClick={() => {
                          history.push({
                            pathname: `/admin-person/add-person`,
                            search: `?id=${item.id}`,
                          });
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
              <tr hidden={admin.length !== 0}>
                <td colSpan="5" className="text-center">
                  Data Not Found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link bg_color"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
};
export default AdminPeron;
