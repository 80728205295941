import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AddPrivate = () => {
  const history = useHistory();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [datas, setDatas] = useState([]);
  const [checkboxListUpdated, setCheckboxListUpdated] = useState(false);
  const jwtToken = localStorage.getItem("jwt_token");
  const { search } = useLocation();

  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [privates, setPrivates] = useState({
    name: "",
    email: "",
    phone_number: "",
    country: "",
    address: "",
    additional_street_info: "",
    city: "",
    state: "",
    postcode: "",
    form: "",
    action_cards_set_no: "",
    password: "",
    remarks: "",
    feature: "",
    customer_id: 0,
    action_card: "",
  });

  const [formValues, setFormValues] = useState([{ id: 1, email: "" }]);
  const [formFamilyValues, setFormFamilyValues] = useState([{ id: 1, email: "" }]);
  const addFormFields = () => {
    const newId = formValues[formValues.length - 1].id + 1;
    setFormValues([...formValues, { id: newId, email: "" }]);
  };
  const addFormFamilyFields = () => {
    const newId = formFamilyValues[formFamilyValues.length - 1].id + 1;
    setFormFamilyValues([...formFamilyValues, { id: newId, email: "" }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    } else {
      setFormValues([{ id: 0 }]);
    }
  };
  const removeFormFamilyFields = (i) => {
    const newFormValues = [...formFamilyValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormFamilyValues(newFormValues);
    } else {
      setFormFamilyValues([{ id: 0 }]);
    }
  };

  const AddPrivateData = async (data, emailArray, emailFamilyArray) => {
    let url = `${process.env.REACT_APP_API_URL}/private`;
    const body = {
      name: privates.name,
      email: privates.email,
      other_email: emailArray.includes(undefined) ? '[]' : emailArray,
      family_members_email: emailFamilyArray.includes(undefined) ? '[]' : emailFamilyArray,
      phone_number: privates.phone_number,
      country: privates.country,
      additional_street_info: privates.additional_street_info,
      street_address: privates.address,
      city: privates.city,
      state: privates.state,
      postcode: privates.postcode,
      form: privates.form,
      action_cards_set_no: privates.action_card.includes(",")
        ? privates.action_card.split(",")
        : [privates.action_card],
      password: privates.password,
      remarks: privates.remarks,
      customer_id: privates.customer_id,
      checkbox: data.join(),
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Data Updated");
          history.push("/Private-person");
        }
      });
  };

  const onChangeHandler = (e, id) => {
    let tempObj = { ...privates };
    if (id) {
      // If an ID is provided, update the corresponding form value
      const formValuesCopy = [...formValues];
      const index = formValuesCopy.findIndex((element) => element.id === id);
      formValuesCopy[index].email = e.target.value;
      setFormValues(formValuesCopy);
    } else {
      // Update other fields
      tempObj[e.target.id] = e.target.value;
      setPrivates(tempObj);
    }
  };
  const onChangeHandler1 = (e, id) => {
    let tempObj = { ...privates };
    if (id) {
      // If an ID is provided, update the corresponding form value
      const formValuesCopy = [...formFamilyValues];
      const index = formValuesCopy.findIndex((element) => element.id === id);
      formValuesCopy[index].email = e.target.value;
      setFormFamilyValues(formValuesCopy);
    } else {
      tempObj[e.target.id] = e.target.value;
      setPrivates(tempObj);
    }
  };

  const onSubmitHandler = (e) => {
    const emailArray = formValues.map((element) => element.email);
    const emailFamilyArray = formFamilyValues.map((element) => element.email);
    const checkedArr = datas
      .filter((data) => {
        if (data.checked === true) {
          return data.id;
        }
      })
      .map((one) => {
        return one.id;
      });
    e.preventDefault();
    if (search) {
      // Update api calling
      const id = paramIdSeprator(search);
      let url = `${process.env.REACT_APP_API_URL}/private/${id}`;
      const body = {
        name: privates.name,
        email: privates.email,
        other_email: emailArray.includes(undefined) ? null : emailArray,
        family_members_email: emailFamilyArray.includes(undefined) ? null : emailFamilyArray,
        phone_number: privates.phone_number,
        country: privates.country,
        additional_street_info: privates.additional_street_info,
        street_address: privates.address,
        city: privates.city,
        state: privates.state,
        postcode: privates.postcode,
        form: privates.form,
        action_cards_set_no: privates.action_card.includes(",")
          ? privates.action_card.split(",")
          : [privates.action_card],
        password: privates.password,
        remarks: privates.remarks,
        customer_id: privates.customer_id,
        checkbox: checkedArr.join(),
      };
      fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.error !== null) {
            toast.error(data.error);
          } else {
            toast.success(data.message);
            history.push("/Private-person");
          }
        });
    } else {
      AddPrivateData(checkedArr, emailArray, emailFamilyArray);
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    const initialData = [...datas];
    initialData.map((e) => {
      e.checked = !isCheckAll;
    });
    setDatas(initialData);
  };

  const onCheckboxHandler = (e, id) => {
    const initialData = [...datas];
    initialData.map((one) => {
      if (one.id === id) {
        one.checked = e.target.checked;
      }
    });
    setDatas(initialData);
  };

  const setCheckboxData = (checkedArray) => {
    const initialData = [...datas];
    initialData.map((one) => {
      if (checkedArray?.includes(one.id)) {
        one.checked = true;
      }
    });
    setDatas(initialData);
  };

  const checkBoxData = async () => {
    let url = `${process.env.REACT_APP_API_URL}/private-features`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data;
        const modifiedData = codeMessage.map((one) => {
          return {
            ...one,
            checked: false,
          };
        });
        setDatas(modifiedData);
        setCheckboxListUpdated(true);
      });
  };

  const getPrivateDetailsById = async (id) => {
    let url = `${process.env.REACT_APP_API_URL}/private/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.privates;
        const codeMessage1 = data.data.privates.checkbox;
        setPrivates({
          name: codeMessage.name,
          email: codeMessage.email,
          phone_number: codeMessage.phone_number,
          country: codeMessage.country,
          address: codeMessage.street_address,
          additional_street_info: codeMessage.additional_street_info,
          city: codeMessage.city,
          state: codeMessage.state,
          postcode: codeMessage.postcode,
          form: codeMessage.form,
          action_cards_set_no: codeMessage.unit_division,
          action_card: codeMessage.unit_division?.length
            ? codeMessage.unit_division
              .map((one) =>
                one.start === one.end ? one.start : `${one.start}-${one.end}`
              )
              .join(", ")
            : "",
          remarks: codeMessage.remarks,
          customer_id: codeMessage.customer_id,
          feature: "",
        });
        const emails = [...formValues];
        emails.email = codeMessage.other_email;
        const ConvertedEmail = emails.email.map((email, index) => {
          return {
            id: index + 1,
            email: email
          }
        })
        setFormValues(ConvertedEmail);
        const FamilyEMail = [...formFamilyValues];
        FamilyEMail.email = codeMessage.family_members_email;
        const ConvertedEmail1 = FamilyEMail.email.map((email, index) => {
          return {
            id: index + 1,
            email: email
          }
        })
        setFormFamilyValues(ConvertedEmail1)
        const updatedCheckboxes = codeMessage1
          ?.split(",")
          .map((e) => parseInt(e));
        setCheckboxData(updatedCheckboxes);
        if (datas?.length === updatedCheckboxes?.length) {
          handleSelectAll();
        }
      });
  };

  useEffect(async () => {
    checkBoxData();
    const id = paramIdSeprator(search);
    if (id && checkboxListUpdated) {
      getPrivateDetailsById(id);
    }
  }, [checkboxListUpdated]);

  return (
    <>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          <div className="col-md-6">
            <label className="mt-1" htmlFor="name">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={privates.name}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={privates.email}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="other_email">
              Other Email address:
            </label>
            {formValues.map((element, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-10 col-md-11 mb-3">
                    <input
                      type="email"
                      key={index}
                      className="form-control"
                      id={`email_${element.id}`}
                      value={element.email}
                      onChange={(e) => onChangeHandler(e, element.id)}
                    />
                  </div>
                  <div className="col-1 col-md-1">
                    <button
                      type="button"
                      className="btn responsive-btn btn-danger"
                      style={{ height: "36px" }}
                      onClick={() => removeFormFields(index)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-md-1 mb-3">
                <button
                  type="button"
                  className="btn responsive-btn btn-primary"
                  onClick={addFormFields}
                >
                  +
                </button>
              </div>
            </div>

            <label className="mt-1" htmlFor="other_email">
              Family Member Email address:
            </label>
            {formFamilyValues.map((element, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-10 col-md-11 mb-3">
                    <input
                      type="email"
                      key={index}
                      className="form-control"
                      id={`email_family${element.id}`}
                      value={element.email}
                      onChange={(e) => onChangeHandler1(e, element.id)}
                    />
                  </div>
                  <div className="col-1 col-md-1">
                    <button
                      type="button"
                      className="btn responsive-btn btn-danger"
                      style={{ height: "36px" }}
                      onClick={() => removeFormFamilyFields(index)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-md-1 mb-3">
                <button
                  type="button"
                  className="btn responsive-btn btn-primary"
                  onClick={addFormFamilyFields}
                >
                  +
                </button>
              </div>
            </div>

            <label className="mt-1" htmlFor="phone_number">
              Phone number:
            </label>
            <input
              type="number"
              className="form-control"
              id="phone_number"
              value={privates.phone_number}
              onChange={onChangeHandler}
            />
            {/* customer_id */}
            <label className="mt-1" htmlFor="customer_id">
              Customer Id :
            </label>
            <input
              type="text"
              className="form-control"
              id="customer_id"
              value={privates.customer_id}
              onChange={onChangeHandler}
            />
            {/* customer_id */}
            <label className="mt-1" htmlFor="customer_id">
              Form :
            </label>
            <input
              type="text"
              className="form-control"
              id="form"
              value={privates.form}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="country">
              Country:
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={privates.country}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="address">
              Street address:
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={privates.address}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6">
            <label className="mt-1" htmlFor="additional_street_info">
              Additional street additional_street_info: :
            </label>
            <input
              type="text"
              className="form-control"
              id="additional_street_info"
              placeholder="Flat, suite, unit, building, floor, door, etc."
              value={privates.additional_street_info}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="city">
              City :
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={privates.city}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="state">
              State/Province/Region:
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={privates.state}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="postcode">
              Postcode:
            </label>
            <input
              type="text"
              className="form-control"
              id="postcode"
              value={privates.postcode}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="action_card">
              Action-cards set no:
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                id="action_card"
                value={privates.action_card}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>

            <label className="mt-1" htmlFor="password">
              Gen. password:
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={privates.password}
              onChange={onChangeHandler}
            />

            <label className="mt-1" htmlFor="remarks">
              Remarks:
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              value={privates.remarks}
              onChange={onChangeHandler}
            />
            <>
              <h5 className="mt-1">Add these features:</h5>
              <div className="row">
                <input
                  type="checkbox"
                  id="selectAll"
                  className="form-check-input"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                  style={{ marginLeft: "4px" }}
                />
                <label
                  className="form-check-label"
                  for="defaultCheck1"
                  style={{ marginLeft: "16px" }}
                >
                  Check all below
                </label>
              </div>
              {datas.map((item, id) => {
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="id"
                      checked={item.checked}
                      onChange={(e) => onCheckboxHandler(e, item.id)}
                    />
                    <label className="form-check-label" htmlFor="id">
                      {item.details}
                    </label>
                  </div>
                );
              })}
            </>
            <button type="Submit" className="btn m-3 bg_color">
              {paramIdSeprator(search) ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPrivate;
