import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { BrowserRouter as Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../components/button/Button";
import * as AuthService from "../services/auth";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  const params = useParams()
  // you can find all params from here
  const token = params.token;

  const resetPassword = async (password) => {
    try {
      await AuthService.resetPassword(password, token)
        .then(() => {
          toast.success("Your Password is Successfully Updated.");
          history.push("/login");
        });
    } catch (error) {
      toast.error("Please enter valid Password.");
    }
  };

  const printFormError = (formik, key) => {
    if (formik.errors[key]) {
      return <div>{formik.errors[key]}</div>;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(1, `${t("password.min")}`)
        .max(30, `${t("password.max")}`)
        .required(`${t("Please enter your password.")}`),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], `${t("Passwords don't match")}`)
        .min(1, `${t("confirmPassword.min")}`)
        .max(30, `${t("confirmPassword.max")}`)
        .required(`${t("Please enter your Confirm password.")}`),
    }),
    onSubmit: (values) => {
      resetPassword(values.password);
    },
  });

  document.getElementById("root").classList = " hold-transition login-page";

  return (
    <div className="login-box">
      <div className="shadow-lg p-3 mb-5 bg-white rounded">
        <div className="user-sidebar mt-3 text-center pb-3 mb-3">
          <img
            src="https://action-cards.com/wp-content/uploads/2022/05/Logo_positiv_CMYK_tagline_h%E2%94%9C%E2%95%95jre_EN_udvidet.svg"
            alt="logo"
            width="80%"
          />
        </div>
        <div className="text-center mb-3">
          <Link to="/reset-password/:token" className="h1">
            <span>Reset Password</span>
          </Link>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group ">
                <input
                  type="password"
                  className="form-control rounded-pill mr-2 ml-2"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                />
                <div className="input-group">
                  <div className="input-group" />
                </div>
              </div>
              {printFormError(formik, "password")}
            </div>

            <div className="mb-3">
              <div className="input-group ">
                <input
                  type="password"
                  className="form-control rounded-pill mr-2 ml-2"
                  placeholder="Confirm Password"
                  {...formik.getFieldProps("confirmPassword")}
                />
                <div className="input-group">
                  <div className="input-group" />
                </div>
              </div>
              {printFormError(formik, "confirmPassword")}
            </div>

            <div className="col-6 mx-auto mt-3 rounded-pill ">
              <Button
                style={{ border: "none" }}
                block
                type="submit"
                className="col-12 btn bg_color"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {t("resetPassword.button.resetPassword.label")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
