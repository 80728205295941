import React, { useState, useEffect } from "react";
// import Select from "react-select";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC" },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630" },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];
const AddOrganisation = () => {
  const [action, setAction] = useState([]);
  const [select, setSelect] = useState(action[0]);

  const [admin, setAdmin] = useState([]);
  const { search } = useLocation();
  const paramIdSeprator = (param) => {
    const paramId = param.split("=");
    return paramId[1];
  };

  const [organisation, setOrganisation] = useState({
    name: "",
    vat: "",
    email: "",
    country: "",
    form: "",
    additional_street_info: "",
    city: "",
    state: "",
    postcode: "",
    remarks: "",
    street_address: "",
    unit: "",
    customer_id: 0
  });
  const jwtToken = localStorage.getItem("jwt_token");
  const addOrg = async () => {
    let url = `${process.env.REACT_APP_API_URL}/organisations`;
    const body = {
      name: organisation.name,
      vat: organisation.vat,
      email: organisation.email,
      country: organisation.country,
      form: organisation.form,
      street_address: organisation.street_address,
      additional_street_info: organisation.additional_street_info,
      city: organisation.city,
      state: organisation.state,
      postcode: organisation.postcode,
      remarks: organisation.remarks,
      customer_id: organisation.customer_id,
    }
    fetch(url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Data Added");
        }
      });
  };

  const onChangeHandler = (e) => {
    let tempObj = { ...organisation };
    tempObj[e.target.id] = e.target.value;
    setOrganisation(tempObj);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(organisation , select.value)
    if (search) {
      // update api call here
      const id = paramIdSeprator(search);
      let url = `${process.env.REACT_APP_API_URL}/organisations/${id}`;
      const body = {
        name: organisation.name,
        vat: organisation.vat,
        email: organisation.email,
        form: organisation.form,
        country: organisation.country,
        street_address: organisation.street_address,
        additional_street_info: organisation.additional_street_info,
        city: organisation.city,
        state: organisation.state,
        postcode: organisation.postcode,
        remarks: organisation.remarks,
        customer_id: organisation.customer_id,
        // feature : isCheck
      };
      // console.log(body);
      fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const codeMessage = data;
          if (codeMessage.error) {
            toast.error(codeMessage.error);
          } else {
            toast.success("Data Updated");
          }
        });
    } else {
      addOrg();
      ClearData();
    }
  };
  const ClearData = (e) => {
    setOrganisation({
      name: "",
      vat: "",
      email: "",
      country: "",
      form: "",
      additional_street_info: "",
      city: "",
      state: "",
      postcode: "",
      remarks: "",
      address: "",
      unit: "",
      customer_id: ""
    });
  };

  const update = async (id, dropDownOptions) => {
    let url = `${process.env.REACT_APP_API_URL}/organisations/${id}`;
    fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.organizations;
        setOrganisation({
          name: codeMessage.name,
          vat: codeMessage.vat,
          email: codeMessage.email,
          country: codeMessage.country,
          form: codeMessage.form,
          additional_street_info: codeMessage.additional_street_info,
          city: codeMessage.city,
          state: codeMessage.state,
          postcode: codeMessage.postcode,
          remarks: codeMessage.remarks,
          street_address: codeMessage.street_address,
          customer_id: codeMessage.customer_id,
        });
        const selectedPerson = dropDownOptions.filter((one) => {
          return one.id === codeMessage.people_id;
        });
        setSelect(selectedPerson);
      });
  };

  const DataPerson = async () => {
    let url = `${process.env.REACT_APP_API_URL}/people`;
    const res = await fetch(url, {
      method: "get",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const codeMessage = data.data.people_details;
        if (codeMessage) {
          codeMessage.map((one) => {
            (one.value = one.id), (one.label = one.first_name);
          });
          setAction(codeMessage);
          return codeMessage;
        }
      });
    return res;
  };
  useEffect(async () => {
    const dropDownOptions = await DataPerson();
    // console.log(a);
    const id = paramIdSeprator(search);
    if (id) {
      update(id, dropDownOptions);
    }
  }, []);
  // console.log(paramIdSeprator);
  return (
    <>
      <div className="row">
        <div className="col-6">{paramIdSeprator(search) ? "Update" : "Add"} Organisation</div>
      </div>
      <form onSubmit={onSubmitHandler}>
        <div className="row">
          {/* Left Side */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="name">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={organisation.name}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="vat">
              VAT:
            </label>
            <input
              type="number"
              className="form-control"
              id="vat"
              value={organisation.vat}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={organisation.email}
              onChange={onChangeHandler}
            />
            {/* <label className="mt-1">
              Contact person:
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={action ? select : []}
              name="color"
              options={action ? action : []}
              onChange={setSelect}
            /> */}
            <label className="mt-1" htmlFor="country">
              Country:
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              value={organisation.country}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="street_address">
              Street address:
            </label>
            <input
              type="text"
              className="form-control"
              id="street_address"
              value={organisation.street_address}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="additional_street_info">
              Additional street info:
            </label>
            <input
              type="text"
              className="form-control"
              id="additional_street_info"
              placeholder="Flat, suite, unit, building, floor, door, etc."
              value={organisation.additional_street_info}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="city">
              City:
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={organisation.city}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="state">
              State/Province/Region:
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={organisation.state}
              onChange={onChangeHandler}
            />
          </div>
          {/* Right Side */}
          <div className="col-md-6">
            <label className="mt-1" htmlFor="postcode">
              Postcode :
            </label>
            <input
              type="number"
              className="form-control"
              id="postcode"
              value={organisation.postcode}
              onChange={onChangeHandler}
            />
            {/* customer_id */}
            <label className="mt-1" htmlFor="customer_id">
              Customer Id :
            </label>
            <input
              type="text"
              className="form-control"
              id="customer_id"
              value={organisation.customer_id}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="customer_id">
              Form :
            </label>
            <input
              type="text"
              className="form-control"
              id="form"
              value={organisation.form}
              onChange={onChangeHandler}
            />
            <label className="mt-1" htmlFor="">
              Remarks:
            </label>
            <textarea
              type="text-area"
              className="form-control"
              id="remarks"
              value={organisation.remarks}
              onChange={onChangeHandler}
            />
            <button type="Submit" className="btn m-3 bg_color">
              {paramIdSeprator(search) ? "Update" : "Add"}
            </button>
            {/* <button type="delete" className="btn m-3  bg_color">
              Delete
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddOrganisation;
